<template>
  <div class="d-flex flex-column h-100 justify-content-between">
    <Header />
    <div class="content container" style="flex-grow: 1">
      <div class="container-title">
        <h3>
          <v-icon color="black" x-large left>mdi-shield-lock</v-icon>Área
          Administrativa
        </h3>
      </div>
      <div class="container-buttons">
        <button
          @click="$router.push({ name: 'Users' })"
          class="dark btn-admin big-btn"
          v-ripple
        >
          <v-icon>mdi-account</v-icon>
          <h5 class="text-left">Gerenciar<br />usuários</h5>
        </button>
        <button
          v-ripple
          @click="$router.push({ name: 'Eventos' })"
          class="btn-admin big-btn blue-codin negative"
        >
          <v-icon>mdi-account-group</v-icon>
          <h5 class="text-left">Gerenciar<br />eventos</h5>
        </button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { ehAdmin } from "../services/api.js";
export default {
  name: "teleAdmin2",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    opcoes: ["Usuários", "Eventos"],
    usuario: true,
  }),
  mounted() {
    const token = localStorage.getItem("token");
    if (!this.Admin()) {
      this.$router.push("/");
    }
    if (token == null) {
      this.$router.push("/");
    }
  },
  methods: {
    escolhe(opcao) {
      if (opcao == "Usuários") this.usuario = true;
      else this.usuario = false;
    },
    async Admin() {
      const id = localStorage.getItem("token");
      const result = await ehAdmin(id);
      if (result.data.isAdmin) {
        return true;
      }

      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.container-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.container-title {
  margin-top: 4rem;
  display: flex;
  justify-content: flex-start;
}
.btn-admin {
  background-color: rgba(61, 61, 61, 1) 31%;
}
.btn-admin:hover {
  background: rgba(61, 61, 61, 6);
}

.big-btn.dark {
  background: #3d3d3d;
  color: white;
}

.big-btn {
  border-radius: 0.5rem;
  padding: 7rem 0 1rem 0 !important;
  height: fit-content !important;
  text-transform: initial;
  max-width: min(100%, 150px) !important;
  letter-spacing: initial !important;
  justify-content: flex-start !important;
  overflow: hidden;
  display: flex;
  position: relative;
  transition: 0.5s;
  margin-top: 0.3rem;

  h5 {
    margin: 0 1.5rem;
  }

  .v-icon {
    position: absolute;
    font-size: 200px;
    top: 10px;
    left: -55%;
    color: white;
    opacity: 0.1;
  }
}

.big-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 32px, rgba(0, 0, 0, 0.1) 0px 2px 16px;
  transition: 0.5s;
  margin-top: 0;
}
</style>
