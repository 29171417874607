import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center botao-inscricao"},[_c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"text-white blue-codin negative",attrs:{"small":"","color":"rgba(61, 61, 61, 1)"}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-pencil-outline ")]),_vm._v(" Inscreva-se ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticStyle:{"border-top":"none !important"}},[_c(VCardTitle,{staticClass:"blue-codin negative"},[_vm._v(" Deseja se inscrever? ")]),_c(VCardText,[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.card.titulo)+" ")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.card.descricao))])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red accent-4","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Voltar ")]),(_vm.btnInscrever)?_c(VBtn,{staticClass:"blue-codin",attrs:{"text":""},on:{"click":_vm.inscrever}},[_vm._v(" Inscrever-se ")]):_c(VBtn,{attrs:{"color":"blue-codin","text":"","disabled":""}},[_vm._v(" Inscrever-se ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }