<template>
  <div class="footer">
    <div
      class="container d-flex flex-column flex-md-row align-start align-md-end gap-4"
    >
      <img
        src="../assets/logo_pge(1).png"
        alt="Logo da PGE"
        class="d-inline-block align-text-top align-baseline logo_img"
      />
      <img
        src="../assets/logo_codin.png"
        alt="Logo da CODIN"
        class="d-inline-block align-text-top align-baseline logo_img"
      />
      <div class="d-flex gap-2 flex-md-row flex-column">
        <div class="footer-element">
          <div class="d-flex">
            <v-icon color="black" class="mr-3">
              mdi-monitor-cellphone
            </v-icon>
            <h6>
              <b>Redes sociais: </b>
            </h6>
          </div>

          <div class="d-flex gap-1">
            <a href="https://www.instagram.com/pge_se/" target="_blank">
              <v-icon color="black" class="">
                mdi-instagram
              </v-icon>
            </a>
            <a
              href="https://www.youtube.com/channel/UCCW3psU0QjWJUtutEZFpSgw"
              target="_blank"
            >
              <v-icon color="black">
                mdi-youtube
              </v-icon>
            </a>
          </div>
        </div>

        <div class="footer-element ml-4">
          <div class="d-flex gap-1">
            <v-icon color="black">
              mdi-domain
            </v-icon>
            <h6> <b>Endereço:</b></h6>
          </div>
          <h6>Rua Porto da Folha nº1116, Cirurgia, 49055-540</h6>
        </div>

        <div class="footer-element ml-3">
          <div class="d-flex gap-1">
            <v-icon color="black">
              mdi-phone-message-outline
            </v-icon>
            <h6>
              <b>Contato:</b>
            </h6>
          </div>
          <h6>
            3198-8000 ou 3198-8006
          </h6>
        </div>
        <div class="footer-element1">
          <div class="d-flex gap-1">
            
            <v-icon color="black" class="mt-n1">mdi-information-outline</v-icon>
              <h6 class=""> <b>Versão:</b> {{version}}</h6> 
            </div>
        </div>
      </div>
      
      </div>
    </div>
  
</template>

<script>
import {getVersion} from "../services/api.js";
export default {
  name: "Footer",
  data() {
    return {
      versao: "",
    };
  },
  computed: {
      version() {
        return this.versao;
      }
    },
  async mounted() {
    const result = await getVersion();
    if(result.status != 204)
      this.versao = result.data.version;
    },
};
</script>

<style scoped>
.footer {
  bottom: 0;
  width: 100vw;

  /* background: rgba(32, 32, 32, 1); */
  background: #9ab3cb;
  padding: 1rem 0;
}
.footer#imagem-footer {
  width: min(80%, 300px);
}

.footer * {
  margin-bottom: 0;
}

/* .imagem-footer {
  height: 100px;
} */

p,
h6 {
  color: black;
}

a,
a:hover {
  text-decoration: none;
}

.footer-element {
  display: flex;
  flex-direction: column;
}
.footer-element1 {
  position: absolute;
  right: 1%;
  bottom: 1%;
  flex-direction: column;
}

.logo_img{

  height:90px;
}
</style>
