import axios from "axios";

//https://localhost:5001
export const api = axios.create({
  baseURL: "https://eventos.pge.se.gov.br/api/",
  //baseURL: "http://homologacao-portal-de-eventos/api/",
  //baseURL: "http://localhost:81/api",
});

export const createSession = async (email, senha) => {
  return api
    .post("/Usuario/Login", { email, senha })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getEventos = async () => {
  return api
    .get("/Evento/GetEvents")
    .then((result) => {
      return result.data;
    })
    .catch((e) => {
      console.error(e);
      return e.message;
    });
};

export const getEventosPassados = async () => {
  return api
    .get("/Evento/GetPastEvents")
    .then((result) => {
      return result.data;
    })
    .catch((e) => {
      console.error(e);
      return e.message;
    });
};

export const getEventosAdmin = async () => {
  return api
    .get("/Evento/GetEventosAdmin")
    .then((result) => {
      return result;
    })
    .catch((e) => {
      console.error(e);
      return e.message;
    });
};


export const ListUsers = async () => {
  return api.get("/Usuario/GetUsers");
};

// export const ListSetores = async () => {
//   return apiSecurity.get("/User/ListSectors");
// }
export const CriarUsuario = async (nome, cpf, email, senha, setor) => {
  return api
    .post("Usuario/Add", { nome, cpf, email, senha, setor })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.request;  
      });
};

export const editarUsuario = async (
  id,
  nome,
  email,
  cpf,
  senha = "123456789"
) => {
  return api
    .put("Usuario/Edit", { id, nome, email, cpf, senha })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      console.error(e);
    });
};

export const acessoUsuario = async (email) => {
  return api
    .put("Usuario/acesso/"+email)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const ActiveUser = async (idUser) => {
  return api.get("/Usuario/ActiveUser/" + idUser);
};

export const DesActiveUser = async (idUser) => {
  return api.get("/Usuario/DesActiveUser/" + idUser);
};

export const excluirUsuario = async (id) => {
  return api.delete("/Usuario/Remove/" + id);
};

export const inscricaoEvento = async (IdEvento) => {
  return api
    .post("/Inscricao/Add", { IdEvento })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const desinscricaoEvento = async (IdEvento) => {
  return api
    .delete("/Inscricao/RemoveInscricao/" + IdEvento)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const inscricaoUsuario = async (IdEvento) => {
  return api
    .get("/Inscricao/VerificaUserInscricao/"+ IdEvento)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const userId = async (Email) => {
  return api
    .post("/Usuario/GetId", { Email })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      console.error(e);
    });
};

export const categorias = async () => {
  return api.get("/Categoria/getCategory");
};

export const getOneCategoria = async (id) => {
  return api
    .get("/Categoria/getOne/" + id)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const categoriasAdmin = async () => {
  return api
    .get("/Categoria/getCategoriaAdmin")
    .then((result) => {
      return result;
    })
    .catch((e) => {
      console.error(e.message);
    });
};

export const addCategoria = async (titulo) => {
  return api
    .post("/Categoria/Add", { titulo })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      console.error(e.message);
    });
};

export const desativarCategoria = async (id) => {
  return api.delete("/Categoria/Remove/" + id);
};

export const editCategoria = async (id, titulo) => {
  return api.put("/Categoria/Edit", { id, titulo });
};

export const toggleCategoria = async (id) => {
  return api.get("/Categoria/toggleCategoria/" + id);
};

export const CriarEvento = async (
  titulo,
  descricao,
  qtdhoras,
  vagas,
  palestrante,
  publicoAlvo,
  dataInicio,
  dataFim,
  categoria,
  certificado = false
) => {
  return api
    .post("/Evento/Add", {
      titulo,
      descricao,
      qtdhoras,
      vagas,
      palestrante,
      publicoAlvo,
      dataInicio,
      dataFim,
      categoria,
      certificado,
    })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};


export const getInscricoes = async () => {
  const str = "/Inscricao/userInscricoes/";
  return api.get(str);
};

export const getEvento = async (id) => {
  const str = "/Evento/getEvento/" + id;
  return api
    .get(str)
    .then((result) => {
      return result;
    })
    .catch(() => {
      return false;
    });
};

export const AddPresenca = async (
  IdEvento,
  IdUsuario
) => {
  return api
    .post("/Presenca/Add", {
      IdEvento,
      IdUsuario
    })
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const ExcluirPresenca = async (idPresenca) => {
  return api.delete("/Presenca/Remove/" + idPresenca);
};

export const GetInfoPresenceByEnrollment = async (IdEvento, IdUsuario) => {
  return api
    .get("/Presenca/GetInfoPresenceByEnrollment?IdEvento="+IdEvento+"&IdUsuario="+ IdUsuario)
    .then((result) => {
      return result;
    })
    .catch(() => {
      return false;
    });
};

export const editEvento = async (
  id,
  titulo,
  descricao,
  qtdhoras,
  vagas,
  palestrante,
  dataInicio,
  dataFim,
  categoria,
  certificado = false
) => {
  return api.put("/Evento/Edit", {
    id,
    titulo,
    descricao,
    qtdhoras,
    vagas,
    palestrante,
    dataInicio,
    dataFim,
    categoria,
    certificado,
  });
};

export const Logout = async () => {
  const token = localStorage.getItem("token");
  return api
    .post("/Usuario/Logout?hash=" + token)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      console.error(e);
    });
};

export const inscricoesEvento = async (idEvento) => {
  return api.get("/Inscricao/inscritosEvento/" + idEvento);
};

export const excluirEvento = async (idEvento) => {
  return api.delete("/Evento/Remove/" + idEvento);
};

export const solicitarSenha = async (email, cpf) => {
  return api
    .post("/TrocaSenhaSolicitada/Add?email="+ email +"&cpf=" + cpf)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const validaCodigo = async (code) => {
  return api.get("/TrocaSenhaSolicitada/VerifyCode?code=" + code).then((result) => {
    return result;
  })
  .catch((e) => {
    return e.response;
  });
};

export const trocarSenha = async (code, senha) => {
  return api.put("/Usuario/ChangePassword?code="+ code +"&senha="+ senha);
};
export const getOne = async () => {
  return api.get("/Usuario/GetOne/");
};

export const userInfo = async (id) => {
  return api.get('/Usuario/userInfo/'+id);
}

export const ehAdmin = async (hash) => {
  return api
    .get("/Usuario/Admin/" + hash)
    .then((result) => {
      return result;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getVersion = async () => {
  return api.get("/ApiVersion/getApiVersion");
}
export const certificado = async (id) => {
  return api.get("/Evento/VerificaEventoCertificado/" + id);
};

export const GetStatusCertificado = async (IdEvento) => {
  return api.get("/Presenca/GetStatusCertificateByEnrollment?IdEvento="+IdEvento);
};

export const liberarCertificado = async (idEvento) => {
  return api.put("/Evento/LiberaCetificado/" + idEvento);
};

export const LiberarCertificadoIndividual = async (IdEvento, IdUsuario) => {
  return api.put("/Evento/LiberaCertificadoIndividual?IdEvento="+IdEvento+"&IdUsuario="+ IdUsuario);
};

export const criarCertificado = async (idInscricao) => {
  return api.post("/Certificado/Add", { idInscricao });
};

export const certificadoInfo = async (idInscricao) => {
  return api.get("/Certificado/GetSingelCertified/" + idInscricao);
};

export const verificarCertificado = async (codigo) => {
  return api
    .get("/Certificado/Verifica/" + codigo)
    .then((result) => {
      return result;
    })
    .catch(() => {
      return false;
    });
};
