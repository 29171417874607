<template>
  <div class="rounded" max-width="200">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Pesquisar"
      outlined
      @input="pesquisa()"
    ></v-text-field>
  </div>
</template>

<script>
//import infosearch from "./infosearch.vue";
export default {
  data: () => ({
    name: "SearchBar",
    searchs: [{}],
    search: "",
    items: [],
  }),
  props: {
    eventos: {
      type: Array,
    },
  },
  // components: {
  //   infosearch
  // },
  methods: {
    pesquisa() {
      this.$emit("pesquisa", this.search);
    },
  },
  // computed: {
  //   keywords() {
  //     if (!this.search) return [];

  //     const keywords = [];

  //     for (const search of this.searching) {
  //       keywords.push(search.keyword);
  //     }

  //     return keywords;
  //   },
  //   searching() {
  //     if (!this.search) return [];

  //     const search = this.search.toLowerCase();

  //     return this.eventos.filter((item) => {
  //       const text = item.titulo.toLowerCase();

  //       return text.indexOf(search) > -1;
  //     });
  //   },
  // },
};
</script>

<style>
.v-list {
  padding: 0px !important;
}
</style>
