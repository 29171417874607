<template>
  <div class="d-flex flex-column h-100" id="verify-certificate">
    <Header />
    <div>
      <div class="container verify-form">
        <h3>
          Verificar autenticidade de Certificado
        </h3>

        <p>
          Insira o código presente no certificado para confirmar sua
          autenticidade
        </p>

        <v-text-field
          label="Código de verificação"
          dense
          v-model="codigo"
          outlined
          required
          class="input-verify"
        >
        </v-text-field>
        <div class="containerBtn">
          <v-btn
            @click="verificaCodigo()"
            color="rgba(61, 61, 61, 1)"
            class="btnV"
          >
            Verificar
          </v-btn>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import { verificarCertificado } from "../services/api.js";

export default {
  name: "VerificarCerticado",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      codigo: null,
    };
  },
  methods: {
    async verificaCodigo() {
      const result = await verificarCertificado(this.codigo);
      if (result.status === 200) {
        const { user, evento } = result.data;
        this.$swal({
          title: "O certificado é válido",
          html: `Certificado encontrado de <strong>${
            user.nome
          }</strong> para o evento <strong>"${
            evento.titulo
          }"</strong> do dia ${new Date(
            evento.dataInicio
          ).toLocaleDateString()} com carga horária de ${evento.qtdHoras} ${
            evento.qtdHoras > 1 ? "horas" : "hora"
          }`,
          icon: "success",
        });
      } else {
        this.$swal(
          "Certificado inválido",
          "Código não corresponde a um certificado existente",
          "error"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.btnV {
  color: white !important;
}
.card-text {
  padding: 2rem;
}

#verify-certificate {
  .verify-form {
    margin-top: 4rem;
    max-width: min(100%, 600px);

    h3 {
      text-align: center;
      font-family: "Merriweather", serif;
      margin-bottom: 2rem;
    }

    .input-verify {
      width: 100%;
    }

    .v-btn {
      width: 100%;
    }
  }
  .footer {
    margin-top: auto;
  }
}
</style>
