<template>
    <div class="text-center botao-desinscricao">
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            color="#800E13"
            class="text-white negative"
          >
            <v-icon small class="mr-1">
              mdi-pencil-outline
            </v-icon>
            Cancelar Inscrição
          </v-btn>
        </template>
        <v-card style="border-top: none !important">
          <v-card-title class="blue-codin negative">
            Deseja cancelar a sua inscrição nesse evento?
          </v-card-title>
  
          <v-card-text>
            <v-card-title class="text-h6 ">
              {{ card.titulo }}
            </v-card-title>
  
            <v-card-subtitle>{{ card.descricao }}</v-card-subtitle>
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--<v-alert type="success" v-show="ok"> Sua inscrição foi cancelada com sucesso</v-alert>
            <v-alert type="error" v-show="erro"> Erro ao cancelar inscrição </v-alert> -->
  
            <v-btn color="red accent-4" text @click="dialog = false">
                Voltar
            </v-btn>
            <v-btn class="blue-codin" text @click="desinscrever" v-if="btnDesinscrever">
                Cancelar Inscrição
            </v-btn>
            <v-btn color="blue-codin" text disabled v-else>
                Cancelar Inscrição
            </v-btn>
          </v-card-actions>
          <v-progress-linear
            :active="loading"
            indeterminate
            color="black darken-2"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import { desinscricaoEvento } from "../services/api.js";

  export default {
    name: "desinscrever",
    props: {
      card: {
        type: Object,
      },
    },
    data() {
      return {
        dialog: false,
        loading: false,
        btnDesinscrever: true,
        //ok: false,
        //erro: false,
      };
    },
    methods: {  
      async desinscrever() {
        this.loading = true;
        this.btnDesinscrever = false;
        const result = await desinscricaoEvento(this.card.id); 
        if (result.status === 200) {
          this.loading = false;
          this.$swal("Sucesso", "Sua inscrição foi cancelada com sucesso", "success");
          this.$emit("verificarDeinscricao", this.card.id);
          location.reload();
        } else if (result.status === 400 || result.status === 401) {
          this.$swal(
            "Error",
            "Você precisa entrar no sistema para cancelar sua inscrição em um evento",
            "error"
          );
          this.$router.push("/login");
          this.btnDesinscrever = true;
        }
        else if(result.status === 500) {
           this.$swal(
            "Error",
            "Você precisa entrar no portal para cancelar sua inscrição em um evento",
            "error"
          );
          this.$router.push("/login");
          this.btnDesinscrever = true;
        }
        else {
          this.$swal("Error", "Erro na cancelação da inscrição", "error");
        }
        setTimeout(() => {
          this.dialog = false;
          this.ok = false;
          this.erro = false;
        }, 1500);
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@/styles/globals.scss";
  </style>
  