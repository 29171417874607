<template>
  <div class="d-flex flex-column justify-content-between h-100">
    <Header />
    <div class="card-curso container pt-5 h-75">
      <div class="row h-100">
        <div class="col-md-2 col-12 espacamento ">
          <Filtragem @filtro="filtra" />
        </div>
        <div class="col-md-10 col-12 pl-sm-8">
          <div class="titulo-curso">
            <div
              class="d-flex justify-content-between w-100 container align-items-center"
            >
              <h2 class="mb-2">
                Eventos
              </h2>
            </div>
            <searchBar :eventos="eventos" @pesquisa="pesquisa"/>
            <div class="text-center">
            </div>
            <div class="cards-container" v-if="!loading">
              <!-- >  For each screen size are a number of columns-->
              <div
                class="card-container"
                v-for="card in this.filteredCards"
                :key="card.id"
              >
                <Card :card="card" />
              </div>
            </div>
            <div v-if="HavePastEvents">
            <hr>
            <h2 class="mb-4 mt-3 ml-3">
                Eventos Passados
            </h2>
            <div class="cards-container" v-if="!loading">
              <!-- >  For each screen size are a number of columns-->
              <div
                class="card-container"
                v-for="(card, index) in historyList"
                :key="index">
                <CardCursoPassado :card="card" />
              </div>  
            </div>
            <div>
                <v-pagination class="pagination mb-5" v-model="page" :length=pages @input="updatePage"/>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Filtragem from "../components/Filtragem.vue";
import Card from "../components/CardCurso.vue";
import CardCursoPassado from "../components/CardCursoPassado.vue"
import SearchBar from "../components/SearchBar";
import { getEventos, getEventosPassados, api, ehAdmin, getInscricoes } from "../services/api.js";
export default {
  name: "telaInicial",
  components: {
    Header,
    Footer,
    Filtragem,
    Card,
    CardCursoPassado,
    SearchBar,
  },
  data() {
    return {
      page: 1,
      pageSize: 6,
      pastCards: 0,
      cards: null,
      logado: false,
      pastevents: [],
      eventos: [],
      anoSelecionado: null,
      mesSelecionado: null,
      loading: true,
      cards_aux: [],
      inscricoes: [],
      filters: {
        year: undefined,
        month: undefined,
        title: undefined,
      },
      listCount: 0,
			historyList: [],
      meses: {
        Janeiro: "01",
        Fevereiro: "02",
        Março: "03",
        Abril: "04",
        Maio: "05",
        Junho: "06",
        Julho: "07",
        Agosto: "08",
        Setembro: "09",
        Outubro: "10",
        Novembro: "11",
        Dezembro: "12",
      },
    };
  },

  async beforeCreate() {
    const token = localStorage.getItem("token");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;


      this.logado = true;

    } else {
      this.logado = false;
    }
  },
  async mounted() {
    
    this.cards = await getEventos();
    this.pastCards = await getEventosPassados();
    this.initPage();
    this.updatePage(this.page);
    if (this.logado) {
      this.inscricoes = await getInscricoes();
      for (const insc of this.inscricoes.data) {
        for (const e of this.cards) {
          if (!e["inscrito"]) {
            e["inscrito"] = e.id === insc.idEvento;
          }
        }
      }
    }
    for (let i of this.cards) {
      this.eventos.push(i);
    }
    for (let i of this.pastCards) {
      this.pastevents.push(i);
    }
    this.cards_aux = this.cards;

    this.loading = false;
  },

  methods: {
    filtra(recv) {
      this.filters.month = recv[0];
      this.filters.year = recv[1];
      this.updatePage(this.page);
      this.listCount = this.filteredPastCards.length;
      this.page = 1;
      this.pages
    },
    pesquisa(recv) {
      this.filters.title = recv;
      this.updatePage(this.page);
      this.listCount = this.filteredPastCards.length;
      this.page = 1;
      this.pages
    },
    initPage() {
      this.listCount = this.filteredPastCards.length;
			if (this.listCount < this.pageSize) {
				this.historyList = this.filteredPastCards;
			} else {
				this.historyList = this.filteredPastCards.slice(0, this.pageSize);
			}
		},
		updatePage(pageIndex) {
			let start = (pageIndex - 1) * this.pageSize;
			let end = pageIndex * this.pageSize;
			this.historyList = this.filteredPastCards.slice(start, end);
			this.page = pageIndex;
		},
    async Admin() {
      const token = localStorage.getItem("token");
      const result = await ehAdmin(token);
      return result.data.isAdmin;
    },
  },

  computed: {
    HavePastEvents(){
      if(this.pastCards.length > 0) return true;
      return false;
    },
    pages() {
			if (this.pageSize == null || this.listCount == null) return 0;
			return Math.ceil(this.listCount / this.pageSize);
		},
    filteredCards: function() {
      return this.cards.filter((value) => {
        let matchYear = true;
        let matchMonth = true;
        let matchTitle = true;

        const [anoV, mesV] = value.dataInicio.split("-");

        if (this.filters.year != undefined) {
          matchYear = anoV == this.filters.year;
        }
        if (this.filters.month != undefined) {
          matchMonth = mesV == this.filters.month;
        }
        if (this.filters.title != undefined) {
          matchTitle =
            value.titulo
              .toLowerCase()
              .includes(this.filters.title.toLowerCase()) ||
            value.descricao
              .toLowerCase()
              .includes(this.filters.title.toLowerCase());
        }

        return matchYear && matchMonth && matchTitle;
      });
    },
    filteredPastCards: function() {
      return this.pastCards.filter((value) => {
        let matchYear = true;
        let matchMonth = true;
        let matchTitle = true;

        const [anoV, mesV] = value.dataFim.split("-");

        if (this.filters.year != undefined) {
          matchYear = anoV == this.filters.year;
        }
        if (this.filters.month != undefined) {
          matchMonth = mesV == this.filters.month;
        }
        if (this.filters.title != undefined) {
          matchTitle =
            value.titulo
              .toLowerCase()
              .includes(this.filters.title.toLowerCase()) ||
            value.descricao
              .toLowerCase()
              .includes(this.filters.title.toLowerCase());
        }

        return matchYear && matchMonth && matchTitle;
      });
    },
  },
};
</script>
<style>
.cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 90px;
}
.pagination {
  display: flex;
  justify-content: center;
  position: relative;
}
.espacamento {
  /* height: 100vh; */
  border-right: 2px solid #888;
  border-bottom: none;
}
@media (max-width: 960px) {
  .espacamento {
    border-right: none;
    border-bottom: 2px solid #888;
  }
  .cards-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr;
  }
}

.space {
  flex-wrap: wrap;
}
</style>
