<template>
  <main class="px-8 py-2">
    <v-data-table
      :headers="headers"
      mult-sort
      :items="categorias"
      items-per-page="5"
      :footer-props="{ itemsPerPageText: 'Categoria por página' }"
    >
      <template v-slot:no-data> Nenhuma Categoria foi encontrada. </template>
      <template v-slot:no-results> Nenhuma Categoria foi encontrada. </template>
      <template v-slot:item.ativo="{ item }">
        <v-switch
          v-model="item.ativo"
          inset
          small
          class="mr-2"
          @click="desativaCategoria(item)"
          color="green"
        ></v-switch>
      </template>
      <template v-slot:item.acoes="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editarCategoria(item)"
          color="blue darken-1"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogAdd" max-width="250px" class="container-dialog">
      <v-card class="container-dialog">
        <v-text-field v-model="addTitulo" label="Titulo" required>
        </v-text-field>

        <div class="btnAdicionarCategoria">
          <v-btn color="primary" dark class="mb-4" @click="criarCategoria()">
            Adicionar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="300px">
      <v-card class="container-dialog">
        <v-card-title class="pa-0 pb-4">Editar categoria</v-card-title>
        <v-text-field
          outlined
          dense
          v-model="editTitulo.titulo"
          label="Titulo"
          required
        >
        </v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogEdit = false">
            CANCELAR
          </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-8 container py-0">
      <v-btn
        color="rgba(61, 61, 61, 1)"
        dark
        class="mb-2 btnNovaCategoria blue-codin negative w-100"
        @click="novaCategoria()"
      >
        <v-icon>mdi-plus</v-icon> Criar nova categoria
      </v-btn>
    </div>
  </main>
</template>

<script>
import {
  categoriasAdmin,
  addCategoria,
  editCategoria,
  toggleCategoria,
} from "../services/api";
export default {
  name: "CategoriaCrud",
  data() {
    return {
      categorias: [],
      editTitulo: { id: 0, titulo: "" },
      dialogEdit: false,
      dialogAdd: false,
      addTitulo: null,
      headers: [
        {
          text: "Titulo",
          value: "titulo",
        },
        {
          text: "Ativo",
          value: "ativo",
        },
        {
          text: "Ações",
          value: "acoes",
        },
      ],
    };
  },
  mounted() {
    this.getCategorias();
  },
  methods: {
    async getCategorias() {
      const result = await categoriasAdmin();
      if (result.status === 200) {
        this.categorias = result.data;
      }
    },
    async desativaCategoria(item) {
      const index = this.categorias.indexOf(item);
      const letCat = this.categorias[index];
      const result = await toggleCategoria(letCat.id);
      if (result.status === 200) {
        this.getCategorias();
      }
    },
    novaCategoria() {
      this.dialogAdd = true;
    },
    async criarCategoria() {
      const result = await addCategoria(this.addTitulo);
      if (result.status === 200) {
        this.dialogAdd = false;
      }
      this.getCategorias();
    },
    editarCategoria(item) {
      this.dialogEdit = true;
      const index = this.categorias.indexOf(item);
      const letCat = this.categorias[index];
      this.editTitulo = letCat;
    },
    async save() {
      const result = await editCategoria(
        this.editTitulo.id,
        this.editTitulo.titulo
      );
      const r = await toggleCategoria(this.editTitulo.id);
      if (result.status === 200 && r.status === 200) {
        this.getCategorias();
      } else {
        this.$swal("Error", "alguma coisa deu errado", "erro");
      }
      this.dialogEdit = false;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnAdicionarCategoria {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-dialog {
  padding: 1rem;
}
</style>
