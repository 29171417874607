<template>
    <div>
        <div class="barra"></div>
        <div class="center">
            <ul class="logo">
                <li style="--i: 1"><img class="imglogo" src="../assets/Logo/1.svg"></li>
                <li style="--i: 2"><img class="imglogo" src="../assets/Logo/2.svg"></li>
                <li style="--i: 3"><img class="imglogo" src="../assets/Logo/3.svg"></li>
                <li style="--i: 4"><img class="imglogo" src="../assets/Logo/4.svg"></li>
                <li style="--i: 5"><img class="imglogo" src="../assets/Logo/5.svg"></li>
            </ul>
         </div>
    </div>
</template>

<script>
    export default {
        name: "Loading",
    }
</script>
   <style lang="scss">
   @import "./Loading.scss";
   </style>