<template>
  <div class="text-center botao-inscricao">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          color="rgba(61, 61, 61, 1)"
          class="text-white blue-codin negative"
          
        >
          <v-icon small class="mr-1">
            mdi-pencil-outline
          </v-icon>
          Inscreva-se
        </v-btn>
      </template>
      <v-card style="border-top: none !important">
        <v-card-title class="blue-codin negative">
          Deseja se inscrever?
        </v-card-title>

        <v-card-text>
          <v-card-title class="text-h6 ">
            {{ card.titulo }}
          </v-card-title>

          <v-card-subtitle>{{ card.descricao }}</v-card-subtitle>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!--<v-alert type="success" v-show="ok"> Inscrição feita com sucesso</v-alert>
          <v-alert type="error" v-show="erro"> Erro na inscrição</v-alert> -->

          <v-btn color="red accent-4" text @click="dialog = false">
            Voltar
          </v-btn>
          <v-btn class="blue-codin" text @click="inscrever" v-if="btnInscrever">
            Inscrever-se
          </v-btn>
          <v-btn color="blue-codin" text disabled v-else>
            Inscrever-se
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { inscricaoEvento } from "../services/api.js";

export default {
  name: "inscrever",
  props: {
    
    card: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      btnInscrever: true,
      //ok: false,
      //erro: false,
    };
  },
  methods: {
    async inscrever() {
      this.loading = true;
      this.btnInscrever = false;
      const result = await inscricaoEvento(this.card.id);
      if (result.status === 200) {
        this.loading = false;
        this.$swal("Sucesso", "Inscrição feita com sucesso", "success");
        this.$emit("verificarInscricao", this.card.id);
        location.reload();
      } else if (result.status === 400 || result.status === 401) {
        this.$swal(
          "Error",
          "Você precisa entrar no sistema para se inscrever em um evento",
          "error"
        );
        this.$router.push("/login");
        this.btnInscrever = true;
      }
      else if(result.status === 500) {
         this.$swal(
          "Error",
          "Você precisa entrar no portal para se inscrever em um evento",
          "error"
        );
        this.$router.push("/login");
        this.btnInscrever = true;
      }
      else if(result.status === 422) {
         this.$swal(
          "Error",
          "O evento não possui vagas disponíveis",
          "error"
        );
      }
      else {
        this.$swal("Error", "Erro na inscrição", "error");
      }
      setTimeout(() => {
        this.dialog = false;
        this.ok = false;
        this.erro = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/globals.scss";
</style>
