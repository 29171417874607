<template>
  <main class="crud-evento">
    <v-data-table
      class="rounded-lg pa-md-8"
      :headers="headers"
      :items="desserts"
      :footer-props="{
        itemsPerPageText: 'Eventos por página',
        itemsPerPageAllText: 'Todos',
      }"
      v-if="!loading"
    >
      <template v-slot:no-data> Nenhum evento foi encontrado. </template>
      <template v-slot:no-results> Nenhum evento foi encontrado. </template>
      <template v-slot:item.dataInicio="{ item }"
        ><span>{{
          new Date(item.dataInicio).toLocaleDateString()
        }}</span></template
      >
      <template v-slot:item.dataFim="{ item }"
        ><span>{{
          new Date(item.dataFim).toLocaleDateString()
        }}</span></template
      >
      <template v-slot:top>
        <div class="pa-2 d-flex flex-column flex-md-row">
          <v-toolbar-title>
            <h3>
              <v-icon color="black" x-large left>mdi-shield-lock</v-icon>Eventos
            </h3>
          </v-toolbar-title>

          <div class="btns-evento ml-md-auto d-flex flex-column flex-md-row">
            <v-btn
              color="rgba(61, 61, 61, 1) "
              dark
              elevation="0"
              class="mb-2"
              @click="criarEvento()"
            >
              Criar evento
            </v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
              color="rgba(61, 61, 61, 1) "
              dark
              elevation="0"
              class="mb-2 blue-codin negative"
              @click="categoria()"
            >
              Categorias de Eventos
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:item.acoes="{ item }">
        <v-icon small class="mr-2" @click="infoEvento(item)" color="green">
          mdi-information-outline
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          color="blue darken-1"
        >
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)" color="red">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>Editar evento</v-card-title>
        <v-card-text>
          <v-form ref="formEventoCrud">
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="editedItem.titulo"
                    label="Titulo"
                    required
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    v-model="editedItem.descricao"
                    label="Descricao"
                    required
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="editedItem.vagas"
                    label="Vagas"
                    type="number"
                    required
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="editedItem.qtdHoras"
                    label="Quantidade de horas"
                    type="number"
                    outlined
                    :rules="[rules.required]"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="editedItem.palestrante"
                    label="Palestrante"
                    required
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="editedItem.dataInicio"
                    label="Data de Inicio"
                    type="date"
                    required
                    :rules="[rules.required, dataInicial, dataFim]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="editedItem.dataFim"
                    label="Data de Fim"
                    type="date"
                    required
                    :rules="[rules.required, dataFim]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    v-model="editedItem.categoria"
                    label="Categoria"
                    required
                    :rules="[rules.required]"
                    outlined
                    dense
                    :items="categorias"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close" :disabled="sending">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="sending">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card style="border-top: none !important">
        <v-card-title class="text-h5 blue-codin negative lighten-2"
          >Deseja excluir esse evento?
        </v-card-title>

        <v-card-text class="mt-6" style="color:black"
          >Essa ação não poderá ser desfeita</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            style="color: #888"
            text
            @click="closeDelete"
            :disabled="sending"
            >Cancelar</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteItemConfirm"
            :disabled="sending"
            >Excluir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogInfo" max-width="60em">
      <v-card>
        <EventoInfo :idEvento="idEventoInfo" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCategoria" max-width="500px">
      <v-card>
        <v-card-title>Gerenciar categorias</v-card-title>
        <CategoriaCrud />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import {
  api,
  getEventosAdmin,
  excluirEvento,
  getEvento,
  editEvento,
  getOneCategoria,
  categorias,
} from "../services/api.js";
import EventoInfo from "../components/EventoInfo.vue";
import CategoriaCrud from "../components/CategoriaCrud.vue";

import dateMixins from "@/mixins/dateMixins.js";

export default {
  name: "eventoCrud",
  components: {
    EventoInfo,
    CategoriaCrud,
  },

  mixins: [dateMixins],

  data() {
    return {
      search: "",
      sending: false,
      dialog: false,
      dialogDelete: false,
      dialogInfo: false,
      dialogCategoria: false,
      idEvento: null,
      idEventoInfo: null,
      loading: true,
      rules: {
        required: (v) => !!v || "Campo obrigatório",
      },
      categorias: [],
      defaultCategoria: "curso",
      headers: [
        { text: "Titulo", value: "titulo" },
        { text: "Palestrante", value: "palestrante" },
        { text: "Total de Vagas", value: "vagas" },
        { text: "Inscritos", value: "inscritos"},
        { text: "Data de início", value: "dataInicio" },
        { text: "Data de encerramento", value: "dataFim"},
        { text: "Ações", value: "acoes", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        titulo: "",
        palestrante: "",
        vagas: "",
      },
    };
  },

  methods: {
    dataInicial(value) {
      const atual = new Date().setHours(0, 0, 0, 0);
      const formatValue = new Date(value).setHours(0, 0, 0, 0);
      return (
        atual <= formatValue ||
        `O evento deve começar após o dia atual (${this.getDateTime(atual)})`
      );
    },

    dataFim() {
      if (this.editedItem.dataFim && this.editedItem.dataFim.length > 9) {
        const dataFim = new Date(this.editedItem.dataFim);
        const dataInicio = new Date(this.editedItem.dataInicio).setHours(
          0,
          0,
          0,
          0
        );
        return (
          dataInicio < dataFim || "Data final deve ser posterior à data inicial"
        );
      } else {
        return true;
      }
    },
    async listarEventos() {
      const result = await getEventosAdmin();
      if (result.status === 200) {
        this.desserts = result.data;
      }
    },
    close() {
      this.dialog = false;
      this.dialogInfo = false;
      this.dialogCategoria = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    categoria() {
      this.dialogCategoria = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.idEvento = item.id;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.sending = true;
        const result = await excluirEvento(this.idEvento);
        if (result.status === 200) {
          this.$swal(
            "Evento excluído",
            `Evento ${
              this.desserts[this.editedIndex].titulo
            } foi excluído com sucesso`,
            "success"
          );
        } else {
          this.$swal(
            "Algo deu errado",
            `Não foi possível excluir o evento ${this.editedItem.titulo}`,
            "error"
          );
        }
        this.desserts.splice(this.editedIndex, 1);
      } catch (e) {
        this.$swal(
          "Algo deu errado",
          `Não foi possível excluir o evento ${this.editedItem.titulo}`,
          "error"
        );
      } finally {
        this.sending = false;
        this.closeDelete();
      }
    },

    criarEvento() {
      this.$router.push("criarEvento");
    },

    async editItem(item) {
      const evento = await getEvento(item.id);
      const categoria = await getOneCategoria(evento.data.idCategoria);

      this.handleCategoria();
      evento.data.categoria = categoria.data.titulo;
      evento.data.dataInicio = new Date(evento.data.dataInicio)
        .toISOString()
        .split("T")[0];
      evento.data.dataFim = new Date(evento.data.dataFim)
        .toISOString()
        .split("T")[0];

      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, evento.data);
      this.editedItem.id = item.id;

      this.dialog = true;
    },

    inverterData(lst) {
      let o = "";
      for (var i = lst.length - 1; i >= 0; i--) {
        o += lst[i];
      }
      return o;
    },
    async handleCategoria() {
      const resultCategorias = await categorias();
      const listCategoria = resultCategorias.data;
      const lstCategoria = [];
      for (let categoria of listCategoria) {
        lstCategoria.push(categoria.titulo);
      }
      this.categorias = lstCategoria;
    },

    async save() {
      this.sending = true;
      try {
        if (this.$refs.formEventoCrud.validate()) {
          if (this.editedIndex > -1) {
            const result = await editEvento(
              this.editedItem.id,
              this.editedItem.titulo,
              this.editedItem.descricao,
              this.editedItem.qtdHoras,
              this.editedItem.vagas,
              this.editedItem.palestrante,
              this.editedItem.dataInicio,
              this.editedItem.dataFim,
              this.editedItem.categoria
            );
            if (result.status === 200) {
              Object.assign(this.desserts[this.editedIndex], this.editedItem);
              this.$swal(
                "Evento editado com sucesso",
                `Evento ${this.editedItem.titulo} foi alterado`,
                "success"
              );
              this.close();
            } else {
              this.$swal(
                "Algo deu errado",
                `Não foi possível alterar o evento ${this.editedItem.titulo}`,
                "error"
              );
            }
          }
        }
      } catch (e) {
        this.$swal(
          "Algo deu errado",
          `Não foi possível alterar o evento ${this.editedItem.titulo}`,
          "error"
        );
      } finally {
        this.sending = false;
      }
    },

    async infoEvento(item) {
      this.dialogInfo = true;
      this.idEventoInfo = item.id;
    },
  },

  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      this.$router.push("/");
      return;
    }
    this.listarEventos();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/globals.scss";

.v-toolbar__content {
  width: 100%;
  justify-content: space-between;
}

.btns-evento {
  display: flex;
  justify-content: left;
}
.tabela {
  border-bottom: none;
}
</style>
