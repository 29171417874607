<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" class="container-dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          color="rgba(61, 61, 61, 1)"
          class="text-white"
        >
          <v-icon small class="mr-1">
            mdi-plus
          </v-icon>
          Informações
        </v-btn>
      </template>
      <v-card style="border-top: none !important">
        <v-card-title class="text-h5 blue-codin negative lighten-2">
          Informações do Curso
        </v-card-title>

        <v-card-text>
          <v-card-title class="text-h6 mb-2 text-nowrap" style="color:black">
            {{ card.titulo }}
          </v-card-title>
          <v-card-subtitle class="text-justify">{{ card.descricao }}</v-card-subtitle>

          <v-card-text style="color: black; padding-bottom: 0;">
            <div class="card-info" v-if="card.qtdHoras">
              <strong>Carga horária:</strong> {{ card.qtdHoras
              }}{{ card.qtdHoras > 1 ? " horas" : " hora" }}
            </div>
            <div class="card-info" v-if="card.palestrante">
              <strong>Palestrante: </strong> {{ card.palestrante }}
            </div>
            <div class="card-info" v-if="card.dataInicio">
              <strong>Data de início: </strong>
              {{ new Date(card.dataInicio).toLocaleDateString() }}
            </div>
            <div class="card-info" v-if="card.publicoAlvo">
              <strong>Público alvo: </strong>
              {{ card.publicoAlvo }}
            </div>
            <div class="card-info">
              <strong>Vagas Restantes: </strong>
              {{ card.vagasRestantes }}
            </div>
          </v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Informacao",
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style>
@media (max-width: 958px) {
  .container-dialog {
    width: 250px;
  }
}
</style>
