<template>
  <main class="d-flex  justify-content-end flex-row align-items-end">
    <v-btn icon class="btn-menu" @click="inicio()">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="black" v-bind="attrs" v-on="on">mdi-home</v-icon>
        </template>
        <span>Ir para tela inicial</span>   
      </v-tooltip>
    </v-btn>

    <v-btn icon class="btn-menu" @click="admin()" v-if="adminBool">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="black" v-bind="attrs" v-on="on">mdi-shield-lock</v-icon>
        </template>
        <span>Ir para tela administrativa</span>   
      </v-tooltip>
    </v-btn>

    <v-btn icon class="btn-menu" @click="user()">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="black" v-bind="attrs" v-on="on">mdi-account</v-icon>
        </template>
        <span>Minha conta</span>
      </v-tooltip>
    </v-btn>

    <v-btn icon class="btn-menu" @click="sair()">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="black" v-bind="attrs" v-on="on">mdi-logout</v-icon>
        </template>
        <span>Sair</span>
      </v-tooltip>
    </v-btn>
  </main>
</template>

<script>
import { api, Logout } from "../services/api.js";
import { ehAdmin } from "../services/api";

export default {
  name: "Menu",
  data() {
  return {
    adminBool: false}
  },
  methods: {
    inicio() {
      this.$router.push("/");
    },
    user() {
      this.$router.push("/usuario");
    },
    admin() {
      this.$router.push("/dashboard").catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
          })
  },
  async verifyAdmin(){
    
    var token = localStorage.getItem("token");
    if (token === null) {
    localStorage.removeItem("token");
    token = undefined;
  }
    const res = await ehAdmin(token);
    if (res.status === 200) this.adminBool = res.data.isAdmin;
  },
    async sair() {
      try {
        if (localStorage.getItem("token") == undefined) {
          this.$router.push({ name: "telaLogin" });
          return;
        }
        const result = await Logout();
        if (result.status === 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          api.defaults.headers.Authorization = null;
          this.$router.push({ name: "telaLogin" });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
  this.verifyAdmin();
}
};
</script>

<style></style>
