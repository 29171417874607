<template>
    <div class="d-flex flex-column h-100 justify-content-between">
      <Header :criaConta="false" />
      <div class="formulario container mx-auto mt-5" style="flex-grow:1">
        <h2 class="text-center mt-12 mb-12">
          Trocar senha
        </h2>
        <v-form class="mb-3" @submit.prevent="Trocar" ref="formTrocar">
          <v-text-field
            v-model="password"
            outlined
            dense
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Nova Senha"
            @click:append="show1 = !show1"
            required
            :rules="[required, min, letraNum]"
            hint="Mínimo de oito caracteres, pelo menos uma letra e um número:"
            value="password"
          ></v-text-field>
          <v-text-field
            v-model="confirmpassword"
            outlined
            dense
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            :rules="[required, confirmarSenhaRules]"
            label="Confirmar Senha"
            @click:append="show2 = !show2"
            required
            value="password"
          ></v-text-field>
  
          <input type="submit" hidden />
  
          <v-btn class="w-100 white--text" color="#1f3566" type="submit">
            Trocar
          </v-btn>
        </v-form>
      </div>
      <Footer/>
    </div>
  </template>
  <script>
  import Header from "../components/Header.vue";
  import Footer from "../components/Footer.vue";
  import { trocarSenha, validaCodigo } from "../services/api";
  export default {
    name: "TrocarSenha",
    components: {
      Header,
      Footer,
    },
    async created(){
      var code = this.$route.params.code;
      var result = await validaCodigo(code);
      if(result.status != 200){
        this.$router.push("/");
      }
    },
    data() {
      return {
        show1: false,
        show2: false,
        password: "",
        confirmpassword: "",
        solicitarSenha: true,
      };
    },
    methods: {
      async Trocar() {
        if (this.$refs.formTrocar.validate()) {
          var code = this.$route.params.code;
          const result = await trocarSenha(code, this.password);
          if (result.status === 200) {
            this.$swal("Successo", "Sua senha foi alterada com sucesso", "Sucess");
            this.$router.push("/login");
          } else {
            this.$swal("Erro", "Ocorreu um erro durante a troca de senha", "Error");
          }
        }
      },
      required: (value) => !!value || "Obrigatório.",
      min: (v) => v.length >= 8 || "Mínimo de 8 caracteres.",
      letraNum: (v) =>
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v) ||
      "Senha deve conter letra e número.",
      confirmarSenhaRules: function(v) {
      return v === this.password || "As senhas não conferem";
    },
    },
  };
  </script>
  <style>
  .formulario {
    margin: 0 200px;
    max-width: 400px;
  }
  </style>
  