<template>
  <div id="inspire" class="d-flex flex-column h-100 justify-content-between">
    <Header />

    <v-main class="grey lighten-3 pt-16">
      <v-container>
        <usuarioCrud />
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import usuarioCrud from "../components/UsuarioCrud.vue";

export default {
  name: "Users",
  components: {
    Header,
    Footer,
    usuarioCrud,
  },
};
</script>
