import Vue from "vue";
import VueRouter from "vue-router";

import TrocarSenha from "../views/TelaTrocarSenha";
import SolicitarTrocaSenha from "../views/SolicitarTrocaSenha";
import telaInicial from "../views/TelaInicial.vue";
import telaLogin from "../views/TelaLogin.vue";
import telaDoUser from "../views/TelaDoUsuario.vue";
import TelaCriarConta from "../views/TelaCadastroUsuario.vue";
import telaCriaEvento from "../views/TelaCriarEvento.vue";
import telaAdmin2 from "../views/TelaAdmin2.vue";
import VerificarCerticado from "../views/TelaVerificarCerticado.vue";
import Users from "../views/TelaUsuarioCrud";
import EventoCrud from "../views/TelaEventoCrud";
//import telaInscricoes from '../views/telaInscricoes.vue';

import { ehAdmin } from "../services/api";
// eslint-disable-next-line no-unused-vars
const beforeEnter = async (to, from, next) => {
  var isAdmin = false;
  var token = localStorage.getItem("token");
  if (token === null) {
    localStorage.removeItem("token");
    token = undefined;
  }

  if (to.meta.requiresAuth) {
    const res = await ehAdmin(token);
    if (res.status === 200) isAdmin = res.data.isAdmin;

    if (isAdmin) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
};
/*enable-eslint*/

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "telaInicial",
    component: telaInicial,
    beforeEnter,
  },
  {
    path: "/login",
    name: "telaLogin",
    component: telaLogin,
    beforeEnter,
  },
  {path: "/solicitarSenha",
  name: "telaSolicitaSenha",
  component: SolicitarTrocaSenha,
  },
  {path: "/trocarSenha/:code",
  name: "telaTrocaSenha",
  component: TrocarSenha,
  },
  /*
    {
        path:'/inscricao',
        name: 'telaInscricao',
        component: telaInscricao,
        beforeEnter,
    },*/
  {
    path: "/criarconta",
    name: "criarConta",
    component: TelaCriarConta,
    beforeEnter,
  },
  {
    path: "/criarevento",
    name: "telaCriaEvento",
    component: telaCriaEvento,
    beforeEnter,
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario",
    name: "telaDoUser",
    component: telaDoUser,
    beforeEnter,
  },
  {
    path: "/dashboard",
    name: "telaAdmin2",
    component: telaAdmin2,
    beforeEnter,
    meta: { requiresAuth: true },
  },
  {
    path: "/verificarCertificado",
    name: "VerificarCerticado",
    component: VerificarCerticado,
    beforeEnter,
  },
  {
    path: "/usuarios",
    name: "Users",
    component: Users,
    beforeEnter,
    meta: { requiresAuth: true },
  },
  {
    path: "/eventos",
    name: "Eventos",
    component: EventoCrud,
    beforeEnter,
    meta: { requiresAuth: true },
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
