<template>
  <v-app id="inspire">
    <Header />

    <v-main class="grey lighten-3 pt-16">
      <v-container>
        <eventoCrud />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import eventoCrud from "../components/EventoCrud.vue";

export default {
  name: "EventoCrud",
  components: {
    Header,
    Footer,
    eventoCrud,
  },
};
</script>

<style scoped></style>
