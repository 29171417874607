<template>
  <div class="inscricoes">
    <h4 class="mb-0">Minhas inscrições:</h4>
    <small
      >Não está conseguindo visualizar seu certificado? É possível que ele ainda
      não tenha sido liberado. Entre em contato com a organização do evento caso
      ache que isso seja um erro.</small
    >

    <div id="eventos" v-if="!loading">
      <div
        v-for="(evento, i) in eventos"
        :key="evento.id"
        elevation="3"
        class="card-inscricao"
        id="lista-cards"
      >
        <div class="content">
          <h5>{{ evento.titulo }}</h5>
          <small>{{ new Date(evento.dataInicio).toLocaleDateString() }}</small>
        </div>

        <div class="actions gap-2 d-flex">
          <cancelarInscricao v-if=!evento.certificado @verificarDeinscricao="ClearCards" :card="evento"/>
          <maisinfos :card="evento"/>
          <Certificado v-if=evento.certificado :evento="evento" :usuario="user" :sub="subs[i].id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import maisinfos from "../components/MaisInfos.vue";
import Certificado from "../components/Certificado.vue";
import cancelarInscricao from "../components/cancelarInscricao.vue";
import { api, getInscricoes, getEvento, getOne, GetStatusCertificado } from "../services/api.js";

export default {
  components: {
    maisinfos,
    Certificado,
    cancelarInscricao,
  },
  data() {
    return {
      subs: [],
      eventos: [],
      presencas: [],
      user: null,
      loading: true,
      dataAtual: "",
    };
  },
  methods: {
    ClearCards : function(){
      
      this.eventos = [];
      this.LoadDate();
    },
    VerifyDate(dataInicio){
      const tempo = Date.now();
      const dataAtual = new Date(tempo).toLocaleDateString();
      return(dataAtual < dataInicio);
    },
    async LoadDate(){
      const token = localStorage.getItem("token");
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const resul = await getInscricoes();
      this.subs = resul.data;
      const u = await getOne();
      if (u.status == 200) {
        this.user = u.data;
      }
      for (let evento of this.subs) {
        let e = await getEvento(evento.idEvento);
        let presenca = await GetStatusCertificado(evento.idEvento);
        this.presencas.push(presenca.data);
        this.eventos.push(e.data);
        console.log(e)
      }

      this.loading = false;
      }
    },
  async mounted() {
    //this.gerarPdf();
    moment.locale("pt-br");
    await this.LoadDate();
  },
};
</script>

<style scoped lang="scss">
@import "./Inscricoes.scss";
</style>
