<template>
  <div>
    <Header />
    <v-form class="forms container pb-16 d-flex flex-column" ref="form">
      <h2 class="text-center mt-4 mb-6">
        Criar Evento
      </h2>
      <v-text-field
        outlined
        v-model="titulo"
        label="Título"
        :rules="[required]"
      >
      </v-text-field>

      <v-textarea
        outlined
        v-model="descricao"
        label="Descrição"
        :rules="[required]"
      >
      </v-textarea>
      <v-text-field
        outlined
        v-model="qthoras"
        label="Carga horária do evento"
        type="number"
        :rules="[required]"
      >
      </v-text-field>
      <v-text-field
        outlined
        v-model="qtVagas"
        label="Quantidade de vagas"
        type="number"
        :rules="[required]"
      >
      </v-text-field>

      <v-text-field
        outlined
        v-model="palestrante"
        label="Palestrante"
        :rules="[required]"
      >
      </v-text-field>

      <v-text-field
        outlined
        v-model="publicoAlvo"
        label="Público alvo"
        :rules="[required]"
      >
      </v-text-field>

      <v-text-field
        outlined
        v-model="dataInicio"
        label="Data de início"
        type="date"
        :rules="[required, dataInicial, dataFimRule]"
      >
      </v-text-field>

      <v-text-field
        outlined
        v-model="dataFim"
        label="Data de encerramento"
        type="date"
        :rules="[required, dataFimRule]"
      >
      </v-text-field>

      <v-select
        no-data-text="Nenhuma categoria encontrada"
        outlined
        label="Categoria"
        :rules="[required]"
        item-text="categoria"
        v-model="defaultCategoria"
        :items="categorias"
      >
      </v-select>

      <v-btn class="blue-codin negative mr-4 w-100" @click="criaEvento">
        Criar Evento
      </v-btn>
    </v-form>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { categorias, CriarEvento } from "../services/api.js";
import moment from "moment";

import dateMixins from "@/mixins/dateMixins.js";

export default {
  name: "telaCriaEvento",

  mixins: [dateMixins],

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      titulo: null,
      descricao: null,
      qthoras: null,
      qtVagas: 0,
      palestrante: null,
      publicoAlvo: null,
      dataInicio: null,
      dataFim: null,
      defaultCategoria: ["curso"],
      categoria: ["curso"],
      categorias: null,
    };
  },

  methods: {
    required: (value) => !!value || "Obrigatório",
    dataInicial(value) {
      const atual = new Date().setHours(0, 0, 0, 0);
      const formatValue = new Date(value).setHours(0, 0, 0, 0);
      return (
        atual <= formatValue ||
        `O evento deve começar após o dia atual (${this.getDateTime(atual)})`
      );
    },

    dataFimRule() {
      if (this.dataFim && this.dataFim.length > 9) {
        const dataFim = new Date(this.dataFim);
        const dataInicio = new Date(this.dataInicio).setHours(0, 0, 0, 0);
        return (
          dataInicio < dataFim || "Data final deve ser posterior à data inicial"
        );
      } else {
        return true;
      }
    },

    async criaEvento() {
      if (this.$refs.form.validate()) {
        const result = await CriarEvento(
          this.titulo,
          this.descricao,
          this.qthoras,
          this.qtVagas,
          this.palestrante,
          this.publicoAlvo,
          this.dataInicio,
          this.dataFim,
          this.categoria[0]
        );
        if (result.status === 200) {
          this.$swal("Sucesso", "Evento criado com sucesso", "success");
          this.$refs.form.reset();
          this.$router.push("/");
        } else {
          this.$swal("Erro", "Verifique os campos do formulário", "error");
        }
      } else {
        this.$swal("Erro", "Verifique os campos do formulário", "error");
      }
    },
  },
  async mounted() {
    moment.locale("pt-br");
    const result = await categorias();
    this.categorias = result.data.map((obj) => {
      return Object.keys(obj).map((key) => {
        return obj[key];
      });
    });
  },
};
</script>

<style></style>
