<template>
    <v-card
      class="mx-auto card d-flex flex-column px-3 pt-2 pb-4"
      style="height: 100%"
      max-width="100%"
      outlined
    >
      <v-list-item two-line class="pa-0">
        <v-list-item-content class="pa-0">
          <div style="color:#AAA" class="text-overline">
            Finalizado Em: {{ dataFormatada }}
          </div>
          <v-list-item-title class="text-h5 mb-1 titulo-evento">
            {{ card.titulo }}
          </v-list-item-title>
          <v-list-item-subtitle style="white-space: inherit !important">{{
            card.descricao
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
  
      <v-card-actions
        class="botoes d-flex gap-2 flex-column flex-sm-row align-start pa-0 mt-8"
      >
        <maisinfospassadas :card="card" />
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import maisinfospassadas from "./MaisInfosPassadas";
  
  export default {
    name: "Lapis",
    components: {
      maisinfospassadas,
    },
    props: {
      card: {
        type: Object,
      },
    },
    methods: {
      inverterData(lst) {
        let o = "";
        o = lst[1] + lst[2] + lst[0];
        return o;
      },
    },
  
    computed: {
      dataFormatada: function() {
        return new Date(this.card.dataFim).toLocaleDateString();
      },
    },
  };
  </script>
  
  <style>
  .v-card {
    border-top: 2px solid black !important;
  }
  
  @media (max-width: 556px) {
    .botoes {
      flex-direction: column;
      justify-content: center;
      gap: 0;
    }
  }
  .card {
    background-color: whitesmoke; 
  }
  </style>
  