<template>
  <div class="d-flex flex-column h-100 justify-content-between">
    <Header />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="forms container"
      style="flex-grow: 1"
    >
      <h2 class="text-center mt-10 mb-8">
        Criar Conta
      </h2>
      <v-text-field
        outlined
        dense
        v-model="name"
        :rules="[required]"
        label="Nome Completo"
        required
        @change="verificarCampos"
      ></v-text-field>

      <v-text-field
        outlined
        v-mask="'###.###.###-##'"
        dense
        v-model="CPF"
        :rules="[CPFRules]"
        label="CPF"
        required
        @change="verificarCampos"
      ></v-text-field>

      <v-text-field
        outlined
        dense
        v-model="email"
        :rules="[emailRequired, emailValido]"
        label="E-mail"
        required
        @change="verificarCampos"
      ></v-text-field>
      <input type="checkbox" class="pa-0" id="checkbox" v-model="checked">
      <label class="pa-1 mt-n1" for="checkbox">Servidor da PGE (Opcional)</label>
      <v-select
        v-if="checked"
        no-data-text="Nenhum setor encontrado"
        outlined
        label="Setor"
        hint="Um setor deve ser selecionado"
        v-model="defaultSetor"
        :items="setores"
      > 
      </v-select>
      <v-text-field
        outlined
        dense
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        v-model="senha"
        :rules="[required, min, letraNum, verificarCampos, verifyPasswords]"
        label="Senha"
        :type="show1 ? 'text' : 'password'"
        required
        name="input-10-1"
        hint="Mínimo de oito caracteres, pelo menos uma letra e um número:"
        value="senha"
        counter
        @click:append="show1 = !show1"
        @change="verificarCampos"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        v-model="confirmarSenha"
        :rules="[required, confirmarSenhaRules, verifyPasswords]"
        label="Confirmar senha"
        ref="inputRef"
        :type="show2 ? 'text' : 'password'"
        required
        name="input-10-1"
        hint="Mínimo de oito caracteres, pelo menos uma letra e um número:"
        counter
        @click:append="show2 = !show2"
        @change="verificarCampos"
      ></v-text-field>
      <!-- <v-checkbox
        v-model="checkbox"
        :rules="[
          (v) => !!v || 'Deve aceitar os termos para efetivar sua inscrição',
        ]"
        label="Concordo com os termos de uso."
        required
      ></v-checkbox> -->
      <v-dialog
        v-model="dialog"
        persistent
        max-width="765"
        >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="w-100 white--text mt-4" color="#1f3566"
        v-bind="attrs"
        v-on="on" :disabled=desativado>
        Criar Usuário
        </v-btn>
      </template>
      
      <v-card style="border-top: none !important">
          <v-card-title class="blue-codin negative">
            Termo de Consentimento
          </v-card-title>
          
          <v-card-text>
            <v-card-title id="aviso">

              <p id="paragrafo">Ao aceitar, você concorda que as informações preenchidas anteriormente, serão coletadas, armazenadas, processadas e compartilhadas para fins de:</p>
            </v-card-title>
            <ul>
              <li> 
                Autenticação no sistema
              </li>
               <li> 
                Contato
              </li>
              <li> 
                Geração de certificados
              </li>
              <li> 
                Registro e Confirmação de identidade
              </li>
            </ul>
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn
            @click="dialog = false"
            color="red accent-4" text>
              Não Aceito
            </v-btn>
            <v-btn 
            @click="CriarConta"
            class="blue-codin" text>
              Aceito
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-alert type="error" v-show="error" class="mt-8">
        <b>{{ errorMessage }}</b>
      </v-alert>
    </v-form>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { validate } from "gerador-validador-cpf";
import { CriarUsuario} from "../services/api.js";

/*
function format(cpf) {
  let newstr = '';
  for (let x of cpf) {
    if (/^[0-9]+$/.test(x)) {
      newstr += x;
    }
  }
  return newstr;
}*/

export default {
  name: "TelaCriarConta",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    errorMessage: "",
    dialog: false,
    valid: true,
    validate,
    name: "",
    email: "",
    senha: "",
    CPF: null,
    confirmarSenha: "",
    checkbox: false,
    checked: false,
    show1: false,
    show2: false,
    error: false,
    defaultSetor: ["Setor"],
    setor: ["setor"],
    setores: ["ASCON - Assessoria Comunicação",  "ASPLAN - Assessoria Planejamento ", 
    "ATOS E CONTRATOS ADMINISTRATIVOS ","CCAC - COORDENADORIA CONSULTIVA DE SERVIÇOS PÚBLICOS", "CCVASP - COORDENADORIA CONSULTIVA DA VIA ADMINISTRATIVA E DE SERVIDOR PÚBLICO ", 
    " CD - Central de Distribuição ", "CE - Centro de Estudos ", " CEDEC - Centro de estudos e demandas estratégicas ", 
    " CGE - Corregedoria Geral do Estado ", " CJC - COORDENADORIA JUDICIAL CÍVEL, ASSUNTOS FUNDIÁRIOS E PATRIMÔNIO PÚBLICO ", 
    " CJC/DHPA - DHPA ", " CJC/NUCRE - NUCRE ", " CJC/NUSA - NUSA ", " CJC/USUCAPIÃO - USUCAPIÃO ", " CJF - COORDENADORIA JUDICIAL FISCAL ", 
    " CJRP - COORDENADORIA JUDICIAL DE RECUPERAÇÃO PATRIMONIAL FISCAL", " CJSP - COORDENADORIA JUDICIAL DE SERVIDOR E EMPREGADO PUBLICOS ", 
    " CJSP/NUSERV - NÚCLEO SERVIDOR ", " CJSP/NUTRAB - NÚCLEO TRABALHISTA ", " CODIN - Coordenadoria de Informática ", 
    " CODISE - Companhia de Desenvolvimento Econômico de SergipeCOFIN - Coordenadoria Financeira ", " COMAP - Coordenadoria de Material e Patrimônio ", 
    " CONSUP - CONSELHO SUPERIOR DA ADVOCACIA-GERAL DO ESTADO ", " CONTAB - Coordenadoria de Contabilidade ", " COPES - Coordenadoria de Pessoal ", 
    " COPROT - Coordenadoria de Protocolo ", " COPSIAD - COMISSÃO PERMANENTE DE SINDICÂNCIA E INQUÉRITO ADMINISTRATIVO DISCIPLINAR ", " CPREV - COORDENADORIA PREVIDENCIÁRIA ",
     " CPREV/ADM - COORDENADORIA PREVIDENCIÁRIA ADMINISTRATIVA ", " CPREV/JUD - COORDENADORIA PREVIDENCIÁRIA JUDICIAL ", " DPRECRPV - Departamento de Precatório e RPV ", 
     " GABIN - Gabinete do Procurador Geral ", " GCG - Gabinete do Corregedor Geral ", " ITPS - Instituto Tecnológico e de Pesquisas do Estado de Sergipe ", 
     " JUCESE - Junta Comercial do Estado de Sergipe ", " NTS - NÚCLEO DE ATUAÇÃO JUNTO AOS TRIBUNAIS SUPERIORES ", " NUPAD - Núcleo de Processos de Advogado Dativo ", 
     " PECC - Procuradoria Especial do Contencioso Cível ", " PFI - Procuradoria Fiscal Itinerante ", " PI - Procuradoria Itinerante ", " PJI - Protocolo Judicial Integrado ", 
     " SEGE - Secretaria Geral ", " SEINFRA - SEINFRA ", "SERGIPEACA - Núcleo SERGIPEPREVIDENCIA Atos e Contratos", " SERGIPECF - Núcleo SERGIPEPREVIDENCIA Fiscal ", 
     " SERGIPECP - SergipePrevidência Cível Pessoal ", " SERGIPEDEPREC/RPV - Núcleo Sergipeprevidência Departamento de Precatórios & RPV da Administração Direta ", 
     " SERGIPEPRE - SERGIPEPREVIDENCIA ", " SERGIPEVA - Núcleo SERGIPEPREVIDENCIA Via Administrativa ", " SERGISUB - Núcleo SERGIPEPREVIDENCIA Subprocuradoria-Geral do Estado ", 
     " SUB Subprocuradoria Geral do Estado ", " Terc - Terceirizados"],
    desativado: true,
  }),
  methods: {
    required: (value) => !!value || "Obrigatório.",
    min: (v) => v.length >= 8 || "Mínimo de 8 caracteres.",
    letraNum: (v) =>
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v) ||
      "Senha deve conter letra e número.",
    emailRequired: (v) => !!v || "E-mail é obrigatório",
    emailValido: (v) =>
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "O e-mail precisa ser válido.",
    CPFRules: (v) => validate(v) || "CPF inválido !",
    confirmarSenhaRules: function(v) {
      return v === this.senha || "As senhas não conferem";
    },
    formataNome(nomecompleto) { // Deixa somente a primeira letra de todo nome em uppercase.
      let nomeFormatado = nomecompleto.toLocaleLowerCase();
      let nomeFormatadoFinal = ''
      var aux;
      for (let palavra of nomeFormatado.split(' ')) {
        aux = palavra[0].toUpperCase() + palavra.slice(1, palavra.length) 
        nomeFormatadoFinal += aux + " "
      }
      return nomeFormatadoFinal.slice(0, nomeFormatadoFinal.length - 1);
    },
    verifyPasswords(){
      if(this.senha === this.confirmarSenha){
        this.$refs.inputRef.resetValidation();
        return true;
      }
    },
    async CriarConta() {
      this.dialog = false;
      if (this.$refs.form.validate()) {
        var varSetor;
        this.name = this.formataNome(this.name);
        if(this.checked) varSetor = this.defaultSetor;
        else varSetor = null;
        const result = await CriarUsuario(
          this.name,
          this.CPF,
          this.email,
          this.senha,
          varSetor,
        );
        if (result.status === 200){
          this.$swal("Sucesso", "Conta criada com sucesso", "success");
          this.$router.push("/login");
        }
        else if (result.status === 409){
          this.errorMessage = decodeURIComponent(JSON.parse(result.response));
          this.error = true;
        }
        else{
          this.errorMessage = decodeURIComponent(JSON.parse(result.response));
          this.error = true;
        }
      } else this.error = true;
    },
      verificarCampos(){
        if ((this.name != "") && (this.email != "") && (this.senha != "") && (this.confirmarSenha != "") && (this.CPF != null)){
          this.desativado = false;
        }
        else{
          this.desativado = true;
        }
      },
  },
  async mounted() {
    const token = localStorage.getItem("token");
    if (token) this.$router.push("/");
    // var resultSetores = await ListSetores();
    // for(var x = 0; x < resultSetores.data.$values.length; x++){
    //   var setenca = resultSetores.data.$values[x].name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    //   setenca = setenca.replace((/\s+[AEIOU]+\s/), letter => letter.toLowerCase());
    //   this.setores.push(setenca + " - " + resultSetores.data.$values[x].abbreviation);
    // }
    // this.setores = this.setores.sort();
  },
};
</script>
<style>
.pagina {
  margin: 0 auto;
  color: grey;
  font-family: "Yaldevi", sans-serif;
}
.alvo {
  border-bottom: 1px solid #888;
  color: black;
}
h2 {
  font-family: "Merriweather", serif;
}
h4 {
  font-size: 1.5rem;
}
.forms {
  max-width: 600px;
}
#paragrafo{
  word-break: break-word;
}
</style>
