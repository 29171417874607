import Vue from 'vue'
import App from './App.vue'
import router from './router';
import vuetify from './plugins/vuetify.js'
import VueMask from 'v-mask';
import VueSweetalert2 from 'vue-sweetalert2';

import "sweetalert2/dist/sweetalert2.min.css";

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(VueSweetalert2);

new Vue({
  render: h => h(App),
  router,
  vuetify
}).$mount('#app');