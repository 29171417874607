import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto card d-flex flex-column px-3 pt-2 pb-4",staticStyle:{"height":"100%"},attrs:{"max-width":"100%","outlined":""}},[_c(VListItem,{staticClass:"pa-0",attrs:{"two-line":""}},[_c(VListItemContent,{staticClass:"pa-0"},[_c('div',{staticClass:"text-overline",staticStyle:{"color":"#AAA"}},[_vm._v(" Início: "+_vm._s(_vm.dataFormatada)+" ")]),_c(VListItemTitle,{staticClass:"text-h5 mb-1 titulo-evento"},[_vm._v(" "+_vm._s(_vm.card.titulo)+" ")]),_c(VListItemSubtitle,{staticStyle:{"white-space":"inherit !important"}},[_vm._v(_vm._s(_vm.card.descricao))]),_c(VListItemSubtitle,{staticStyle:{"font-weight":"bold","padding-top":"1rem"}},[_vm._v(" Vagas Restantes: "+_vm._s(_vm.card.vagasRestantes)+" ")])],1)],1),_c(VCardActions,{staticClass:"botoes d-flex gap-2 flex-column flex-sm-row align-start pa-0 mt-8"},[(!_vm.getInscricao)?_c('inscrever',{attrs:{"card":_vm.card},on:{"verificarInscricao":_vm.turnOffEnrollment}}):_vm._e(),(_vm.getInscricao)?_c('cancelarInscricao',{attrs:{"card":_vm.card},on:{"verificarDeinscricao":_vm.turnOnEnrollment}}):_vm._e(),_c('maisinfos',{attrs:{"card":_vm.card}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }