<template>
  <main ref="info" class="px-8">
    <div class="px-0">
      <h3 class="tituloEvento ma-0 mt-5">{{ tituloEvento }}</h3>
    </div>
    <div class="d-flex flex-column infoEvento">
      <div class="d-flex flex-column my-4">
        <div class="info-event">
          <v-icon class="icon-sub">mdi-account-multiple-check </v-icon>
          <strong class="info-number" v-if="quantidadeInscritos > 1"
            >{{ quantidadeInscritos }} inscritos</strong
          >
          <strong v-else>{{ quantidadeInscritos }} inscrito</strong>
        </div>
        <div class="info-event">
          <v-icon class="icon">mdi-account-multiple</v-icon>
          <strong class="info-number" v-if="quantidadeRestante > 1"
            >{{ quantidadeRestante }} vagas restantes</strong
          >
          <strong v-else
            >{{ quantidadeRestante }} vaga restante</strong
          >
        </div>
      </div>
      <br>
      <h3 class="align-self-center pa-3">Gerenciamento de Inscritos </h3>
      <v-text-field v-model="search" label="Pesquisar Usuário" class="w-50 align-self-center pt-4" clearable outlined hide-details append-icon="mdi-magnify"></v-text-field>
      <v-data-table class="title-table"
        :headers="headers"
        :items="filteredItems"
        no-data-text="nenhum usuário inscrito"
        :footer-props="{ itemsPerPageText: 'Inscritos por página' }"
      >
      <template v-if="individualCertificateMode" v-slot:item.checkCertificado="{ item }">
          <v-checkbox
            v-model="selected"
            :value="item"
            :change="verifyRelease()"
          ></v-checkbox>
        </template>
      <template v-slot:item.Presenca="{item}">
        <v-switch v-model="item.valorPresenca" @click="changeSwitch(item)">
          <template v-slot:label>
            <span>{{ item.presenca }}</span>
          </template>
        </v-switch>
      </template>
      </v-data-table>
      
      <div class="d-flex justify-center container-botao-individual" v-if="!individualCertificateMode">
        <v-btn
          color="rgba(61, 61, 61, 1)"
          dark
          @click="activeCheckBox()"
          :disabled="sending"
        >
          Liberar certificados individualmente
        </v-btn>
      </div>
      <div class="d-flex justify-center container-botao-individual" v-else>
        <v-btn
          color="green" class="container-botao-cancelar btn-block"
          dark
          @click="activeCheckBox(),certificadoIndividual()"
        :disabled="!canRelease"
        >
          Liberar certificados
        </v-btn>
        <v-btn class="container-botao-cancelar"
          color="red"
          dark
          @click="activeCheckBox"
          :disabled="sending"
        >
          Cancelar
        </v-btn>
      </div>
      <div class="d-flex justify-center container-botao">
        <v-btn
          color="rgba(61, 61, 61, 1)"
          dark
          @click="certificado()"
          :disabled="sending"
        >
          Liberar certificado para os participantes presentes
        </v-btn>
      </div>
      
    </div>
  </main>
  
</template>



<script>
import {
  inscricoesEvento,
  getEvento,
  api,
  liberarCertificado,
  GetInfoPresenceByEnrollment,
  AddPresenca,
  ExcluirPresenca,
  LiberarCertificadoIndividual
} from "../services/api.js";

export default {
    name: "EventoInfo",
    props: {
        idEvento: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            individualCertificateMode: false,
            selected: [],
            teste: true,
            editIndex: -1,
            search: "",
            sending: false,
            inscritos: null,
            users: [],
            quantidadeInscritos: 0,
            quantidadeRestante: 0,
            tituloEvento: "",
            eventoObj: null,
            canRelease: false,
            headers: [
                { text: "", value: "checkCertificado" },
                { text: "", value: "IdPresenca" },
                {
                    text: "Nome",
                    value: "nome",
                },
                {
                    text: "E-mail",
                    value: "email",
                },
                {
                    text: "Presenca",
                    value: "Presenca",
                },
            ],
        };
    },
    computed: {
        verifyItems() {
            return this.selected;
        },
        filteredItems() {
        if(this.search == "" || this.search == null){
          return this.users
        }
        else{
          return this.users.filter(user =>
          user.nome.toLowerCase().includes(this.search.toLowerCase())
          || user.email.toLowerCase().includes(this.search.toLowerCase())
          );
        }
      }
    },
    async mounted() {
        this.carregaEvento(this.idEvento);
    },
    methods: {
        verifyRelease() {
            if (this.selected.length > 0) {
                this.canRelease = true;
            }
            else {
                this.canRelease = false;
            }
        },
        activeCheckBox() {
            this.individualCertificateMode = !this.individualCertificateMode;
        },
        insertInSelectedList() {
        },
        async GetInfoPresenceByEnrollment() {
            for (let usuarios of this.inscritos) {
                let usuarioId = usuarios["idUsuario"];
                const u = await GetInfoPresenceByEnrollment(this.idEvento, usuarioId);
                this.users.push(u.data);
            }
        },
        async eventoInfo() {
            const evento = await getEvento(this.idEvento);
            if (evento.status === 200) {
                this.eventoObj = evento.data;
                this.quantidadeRestante = evento.data.vagasRestantes;
                this.tituloEvento = evento.data.titulo;
            }
        },
        async carregaEvento(id) {
            this.users = [];
            const token = localStorage.getItem("token");
            api.defaults.headers.Authorization = `Bearer ${token}`;
            const result = await inscricoesEvento(id);
            if (result.status === 200) {
                this.inscritos = result.data;
                this.quantidadeInscritos = this.inscritos.length;
                await this.eventoInfo();
                await this.GetInfoPresenceByEnrollment();
            }
        },
        async changeSwitch(item) {
            this.editedIndex = this.users.indexOf(item);
            if (item.presenca == "Ausente") {
                this.users[this.editedIndex].valorPresenca = true;
                this.users[this.editedIndex].presenca = "Presente";
                const result = await AddPresenca(this.idEvento, item.idUsuario);
                if (result.status === 200) {
                    item.idPresenca = result.data.id;
                }
            }
            else {
                this.users[this.editedIndex].valorPresenca = false;
                this.users[this.editedIndex].presenca = "Ausente";
                await ExcluirPresenca(item.idPresenca);
            }
            // if(this.users[this.editedIndex].valorPresenca){
            //   this.users[this.editedIndex].valorPresenca = false;
            //   console.log(this.users[this.editedIndex].valorPresenca)
            //   this.users[this.editedIndex].presenca = "Ausente";
            // }
            // else{
            //   this.users[this.editedIndex].presenca = "Presente";
            //   this.users[this.editedIndex].valorPresenca = true;
            //   this.teste = false;
            // }
        },
        async certificado() {
            try {
                this.sending = true;
                const req = await liberarCertificado(this.idEvento);
                if (req.status === 200) {
                    this.$swal("Sucesso", "Usuários presentes no evento podem gerar o certificado", "success");
                }
                else {
                    this.$swal("Algo deu errado", `Não foi possível liberar o certificado`, "error");
                }
            }
            catch (e) {
                this.$swal("Algo deu errado", `Não foi possível liberar o certificado`, "error");
            }
            finally {
                this.sending = false;
            }
        },
        async certificadoIndividual() {
            try {
                this.sending = true;
                for (var i = 0; i < this.selected.length; i++) {
                    var index = this.users.indexOf(this.selected[i]);
                    const req = await LiberarCertificadoIndividual(this.idEvento, this.users[index].idUsuario);
                    if (req.status === 200) {
                        this.$swal("Sucesso", " O(s) usuário(s) agora pode(m) gerar o certificado", "success");
                        this.selected = [];
                    }
                    else {
                        this.$swal("Algo deu errado", `Verifique se todos os usuários selecionados estão presentes no evento`, "error");
                    }
                }
            }
            catch (e) {
                this.$swal("Algo deu errado", `Verifique se todos os usuários selecionados estão presentes no evento`, "error");
            }
            finally {
                this.sending = false;
            }
        },
    },
    watch: {
        idEvento(value) {
            this.carregaEvento(value);
        },
    },
};
</script>

<style scoped lang="scss">
@import "./EventoInfo.scss";
</style>
