<template>
  <v-app>
    <Loading v-if="show"/>
    <router-view> </router-view>
  </v-app>
</template>

<script>

import {api} from '../src/services/api.js';
import Loading from '../src/components/Loading.vue';
export default {
  name: "App",
  components: {
    Loading,
  },
  data(){
    return{
      show: "",
    }
  },

  created: function (){
    api.interceptors.request.use(config => {
    this.show = true; 
    return config;
    });
    api.interceptors.response.use(
    response => {
        this.show = false;
        return Promise.resolve(response);
    },
    error => {
        this.show = false;
        if (error.response && error.response.status) {
          if(error.response.status === 401){
            if(!error.response.data === "Já existe esse usuário"){
              localStorage.removeItem("token")
              this.$swal(
              "Login Expirado",
              "Você foi redirecionado para a tela de login ",
              "error"
            );
            this.$router.push("/login");
            }
            
            
          }
    }
        return Promise.reject(error);
    }
);
  },
};
</script>

<style lang="scss">
@import "./styles/globals.scss";

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to top, #9c9c9c, #cfcfcf);
  font-family: Arial, Helvetica, sans-serif;
}

legend {
  float: inherit !important;
}

.blue-codin {
  color: $blue-codin !important;
}

.blue-codin.negative {
  color: white !important;
  background: $blue-codin !important;
}
</style>
