<template>
    <div class="d-flex flex-column h-100 justify-content-between">
      <Header :criaConta="false" />
      <div class="formulario container mx-auto mt-5" style="flex-grow:1">
        <h2 class="text-center mt-12 mb-12">
          Solicitar Nova Senha
        </h2>
        <v-form class="mb-3" @submit.prevent="Solicitar" ref="formSolicitar">
          <v-text-field
            v-model="email"
            outlined
            dense
            label="E-mail"
            required
            :rules="[required]"
            value="email"
            type="email"
          ></v-text-field>
          <v-text-field
            v-model="cpf"
            outlined
            dense
            :rules="[CPFRules]"
            v-mask="'###.###.###-##'"
            label="CPF"
            required
            type="cpf"
            value="cpf"
          ></v-text-field>
  
          <input type="submit" hidden />
  
          <v-btn class="w-100 white--text" color="#1f3566" type="submit">
            Solicitar
          </v-btn>
        </v-form>
      </div>
      <Footer />
    </div>
  </template>
  <script>
  import Header from "../components/Header.vue";
  import Footer from "../components/Footer.vue";
  import { validate } from "gerador-validador-cpf";
  import { solicitarSenha} from "../services/api";
  export default {
    name: "SolicitarTrocaSenha",
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        email: "",
        cpf: "",
        solicitarSenha: true,
        required: (v) => !!v || "Campo obrigatório",
      };
    },
    methods: {
      async Solicitar() {
        if (this.$refs.formSolicitar.validate()) {
          const result = await solicitarSenha(this.email, this.cpf);
          if (result.status === 200) {
            this.$swal("Sucesso", "Nos próximos minutos, uma mensagem para modificar a sua senha será encaminhada para o email cadastrado. Verifique também a caixa de spam", "success");
            this.$router.push("/login");
          } 
          else if(result.status === 405) {
            this.$swal("Alerta", "Conta não encontrada para este E-mail e CPF", "warning");
          }
          else {
            this.$swal("Erro", "Ocorreu um erro durante a solicitação da troca de senha", "error");
          }
        }
      },
      CPFRules: (v) => validate(v) || "CPF inválido !",
    },
  };
  </script>
  <style>
  .formulario {
    margin: 0 200px;
    max-width: 400px;
  }
  </style>
  