import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center botao-desinscricao"},[_c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"text-white negative",attrs:{"small":"","color":"#800E13"}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-pencil-outline ")]),_vm._v(" Cancelar Inscrição ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticStyle:{"border-top":"none !important"}},[_c(VCardTitle,{staticClass:"blue-codin negative"},[_vm._v(" Deseja cancelar a sua inscrição nesse evento? ")]),_c(VCardText,[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.card.titulo)+" ")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.card.descricao))])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red accent-4","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Voltar ")]),(_vm.btnDesinscrever)?_c(VBtn,{staticClass:"blue-codin",attrs:{"text":""},on:{"click":_vm.desinscrever}},[_vm._v(" Cancelar Inscrição ")]):_c(VBtn,{attrs:{"color":"blue-codin","text":"","disabled":""}},[_vm._v(" Cancelar Inscrição ")])],1),_c(VProgressLinear,{attrs:{"active":_vm.loading,"indeterminate":"","color":"black darken-2"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }