<template>
  <div class="d-flex flex-column h-100 justify-content-between">
    <Header :criaConta="criaConta" />
    <div class="formulario container mx-auto mt-5" style="flex-grow:1">
      <h2 class="text-center mt-12 mb-12">
        Login
      </h2>
      <v-form class="mb-3" @submit.prevent="Login" ref="formLogin">
        <v-text-field
          v-model="email"
          outlined
          dense
          label="E-mail"
          required
          :rules="[required]"
          value="email"
          type="email"
        ></v-text-field>
        <v-text-field
          v-model="senha"
          outlined
          dense
          :rules="[required]"
          label="Senha"
          required
          type="password"
          value="senha"
        ></v-text-field>
        <input type="submit" hidden />
        <v-btn class="w-100 white--text" color="#1f3566" type="submit">
          Entrar
        </v-btn>
        <div
          class="mt-3 create-account"
        >
        <a href="/solicitarSenha">Esqueceu sua senha?</a>
        </div>
      </v-form>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { createSession, api } from "../services/api.js";
export default {
  name: "telaLogin",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      email: "",
      senha: "",
      criaConta: true,
      required: (v) => !!v || "Campo obrigatório",
    };
  },
  methods: {
    async Login() {
      if (this.$refs.formLogin.validate()) {
        const result = await createSession(this.email, this.senha);
        const token = result.data.access_token;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        if (result.status === 200) {
          localStorage.setItem("token", token);
          this.$router.push("/");
        
        }else if(result.status === 403){
          this.$swal("Error", "Usuário desativado", "error");
        }else if (result.status === 401) {
          this.$swal("Error", "Email ou senha inválidos", "error");
        } else {
          this.$swal("Erro", "algo deu errado", "error");
        }
      }
    },
  },
  created() {
    if (localStorage.getItem("token") != null) {
      this.$router.push("/");
    }
  },
};
</script>
<style>
.formulario {
  margin: 0 200px;
  max-width: 400px;
}

@media screen and (max-width: 756px) {
  .formulario {
    margin: 10px;
  }
}
#btn-entrar,
#btn-entrar:focus {
  background-color: #dcdcdf99 !important;
}
.create-account{
  text-align: center;
}
.create-account p {
  color: #888;
  margin-bottom: 0;
}
.create-account p,
.create-account a {
  font-size: 1rem;
}
</style>
