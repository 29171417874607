<template>
  <div class="titulo">
    <nav class="navbar pa-0">
      <div
        class="container text-white align-items-center portal-header align-center"
      >
        <div
          class="justify-content-center col-sm-3 col-12 pa-0 d-flex justify-content-sm-start"
        >
          <img
            @click="home()"
            src="../assets/logo-colorida.png"
            alt="Logo da CEDEC"
            class="d-inline-block align-text-top pointer"
          />
        </div>
        <div
          class="justify-content-center text-center col-0 col-sm-6  pa-0 d-none d-md-inline"
        >
          <h2 class="mb-0 ">Portal de Eventos e Certificados</h2>
        </div>
        <div
          class="justify-content-end text-right col-sm-3 col-12 pa-0 d-flex justify-content-center justify-content-sm-end"
        >
          <v-btn class="botao pd-6" rounded to="/criarconta" v-if="criaConta">
            Criar Conta
          </v-btn>
          <v-btn class="botao pd-6" rounded to="/login" v-else-if="!logado">
            Login
          </v-btn>
          <Menu v-else />
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { api } from "../services/api.js";
import Menu from "./Menu.vue";
export default {
  name: "Header",
  components: {
    Menu,
  },
  data: () => ({
    logado: true,
  }),
  props: {
    criaConta: {
      type: Boolean,
    },
  },
  computed: {
    getroute: function() {
      return this.$route.name;
    },
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    logout() {
      localStorage.removeItem("token");
      api.defaults.headers.Authorization = null;
      this.$router.push("/login");
    },
    sub() {
      this.$router.push("/inscricoes");
    },
  },

  mounted() {
    this.logado = localStorage.getItem("token");
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.7rem;
}
@media (max-width: 756px) {
  h2 {
    display: none;
    font-family: Arial, Helvetica, sans-serif;
  }
  .portal-header {
    justify-content: space-evenly;
  }
}
.navbar {
  width: 100%;
  /* background-image: radial-gradient(
    circle,
    rgba(29, 29, 27, 1) 0%,
    rgba(61, 61, 61, 1) 31%,
    rgba(29, 29, 27, 1) 100%
  ); */
  /*background-color: #ecdfca;*/
  /* background-color: #D8D9DA; */ 
  background-color: #9ab3cb;
  /*background-color: #F7F5F2; */
  /*background-color: #555555; */
  height: 120px;
}
.navbar img {
  width: 200px;
  cursor: pointer;
}

.title-page {
  position: absolute;
  inset: 0; /* reseta as margens, só funciona com absolute, ao colocar 0 ele ocupa todo o espaço*/
  z-index: 555; /* trabalha com a profundidade da 'div', ou seja, trabalha com sobreposição*/
  justify-content: center;
  align-items: center;
}

h2 {
  color: black;
}
.title-page h2 {
  font-weight: 700;
  margin: 0;
  
  font-family: "Merriweather", serif;
}
.botao {
  z-index: 666;
  padding: 10px;
}
</style>
