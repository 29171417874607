<template>
  <div id="filtragem" class="mr-sm-5">
    <p class="title-filtragem mb-5">
      Filtragem
    </p>

    <v-select
      outlined
      @change="filtro"
      @input="filtro"
      v-model="mesSelecionado"
      clearable
      class="mb-5"
      hide-details
      placeholder="Mês"
      :items="items"
      item-value="id"
      item-text="month"
      label="Mês"
      min-width="50"
    ></v-select>
    <v-select
      outlined
      @change="filtro"
      clearable
      hide-details
      placeholder="Ano"
      :items="anos"
      label="Ano"
      v-model="anoSelecionado"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "Filtragem",
  data: () => ({
    items: [
      { month: "Janeiro", id: 1 },
      { month: "Fevereiro", id: 2 },
      { month: "Março", id: 3 },
      { month: "Abril", id: 4 },
      { month: "Maio", id: 5 },
      { month: "Junho", id: 6 },
      { month: "Julho", id: 7 },
      { month: "Agosto", id: 8 },
      { month: "Setembro", id: 9 },
      { month: "Outubro", id: 10 },
      { month: "Novembro", id: 11 },
      { month: "Dezembro", id: 12 },
    ],

    anos: [],
    checkbox: false,
    anoSelecionado: null,
    mesSelecionado: null,
  }),
  created() {
    var currentYear = new Date().getFullYear() + 1; //data e ano atual
    var qtt = currentYear - 2000 + 1; // quantidade de anos a ser mostrado.
    var years = [...Array(qtt).keys()]; // numeração das posicoes do array.
    this.anos = years.map((indice) => currentYear - indice); // passa a posicao do array para o indice.
  },
  methods: {
    filtro() {
      let filters = [this.mesSelecionado, this.anoSelecionado];
      this.$emit("filtro", filters);
    },
  },
};
</script>

<style>
.title-filtragem {
  font-family: "Yaldevi", sans-serif;
}

.v-label {
  margin-bottom: 0 !important;
}
</style>
