<template>
  <main>
    <div class="text-center">
    </div>
    <v-data-table
      class="rounded-lg pa-md-8"
      :headers="headers"
      :items="desserts"
      :footer-props="{
        itemsPerPageText: 'Usuários por página',
        itemsPerPageAllText: 'Todos',
      }"
      v-if="!loading"
      :search="search"
    >
      <template v-slot:no-data> Nenhum usuário foi encontrado. </template>
      <template v-slot:no-results> Nenhum usuário foi encontrado. </template>

      <template v-slot:top>
        <div class="pa-2 w-100 d-flex flex-column flex-md-row">
          <v-toolbar-title>
            <h3>
              <v-icon color="black" x-large left>mdi-shield-lock</v-icon
              >Usuários
            </h3>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            outlined
            dense
            label="Pesquisar usuário"
            append-icon="mdi-magnify"
            type="text"
            style="max-width: 300px;"
          ></v-text-field>
        </div>
      </template>

      <template v-slot:item.acoes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-bind="attrs"
          v-on="on"
          color="blue darken-1"
        >
          mdi-pencil
        </v-icon>
          </template>

          <span>Editar usuário</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="!item.isActive" small class="mr-2" @click="ativaItem(item)" v-bind="attrs"
              v-on="on" color="green">
              mdi-account
            </v-icon>
            <v-icon v-else small class="mr-2" @click="desativaItem(item)" v-bind="attrs"
              v-on="on" color="red">
              mdi-account
            </v-icon>
          </template>

          <span v-if="!item.isActive"
            >Ativar usuário</span>
          <span v-else>Desativar usuário</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="acessoOk(item, item.admin)"
              v-bind="attrs"
              v-on="on"
              :color="getColor(item)"
            >
              mdi-lock-open-check
            </v-icon>
          </template>

          <span v-if="!item.admin"
            >Dar acesso de administrador a esse usuário?</span
          >
          <span v-else>Retirar acesso desse usuário</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500px" class="teste">
      <v-card>
        <v-card-title>Editar usuário</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="editedItem.nome"
                  hide-details
                  label="Nome"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="editedItem.email"
                  hide-details
                  label="Email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="close" :disabled="sending">
            CANCELAR
          </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="sending">
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogActive" max-width="500px">
      <v-card style="border-top: none !important">
        <v-card-title
          class="text-h5 blue-codin negative lighten-2"
          style="word-break: break-word"
        >
          Tem certeza que quer ativar esse usuário?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#444444" text @click="closeDelete" :disabled="sending"
            >Cancelar</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="ativaItemConfirm"
            :disabled="sending"
            >Ativar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDesactive" max-width="500px">
      <v-card style="border-top: none !important">
        <v-card-title
          class="text-h5 blue-codin negative lighten-2"
          style="word-break: break-word"
        >
          Tem certeza que quer desativar esse usuário?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#444444" text @click="closeDelete" :disabled="sending"
            >Cancelar</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="desactiveItemConfirm"
            :disabled="sending"
            >Desativar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAcesso" max-width="580px">
      <v-card style="border-top: none !important">
        <v-card-title
          class="text-h5 blue-codin negative lighten-2"
          style="word-break: break-word"
          >Deseja {{ msgAdmin }} acesso de administrador a esse
          usuário?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#444444" text @click="closeDelete" :disabled="sending"
            >CANCELAR</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="acesso()"
            :disabled="sending"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import {
  api,
  ListUsers,
  userId,
  editarUsuario,
  ActiveUser,
  DesActiveUser,
  acessoUsuario,
} from "../services/api.js";
export default {
  name: "usuarioCrud",
  data() {
    return {
      search: "",
      sending: false,
      dialog: false,
      dialogActive: false,
      dialogAcesso: false,
      dialogDesactive: false,
      loading: true,
      msgAtivar: "Ativar",
      msgAdmin: "conceder",
      itemSelect: null, 
      headers: [
        { text: "Nome", value: "nome" },
        { text: "Email", value: "email" },
        { text: "CPF", value: "cpf" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        Nome: "",
        Email: 0,
        cpf: 0,
      },
      defaultItem: {
        Nome: "xxxxx",
        Email: "xxxxxxxx",
        cpf: "xxxxxxx",
      },
    };
  },
  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      this.$router.push("/");
      return;
    }
    this.listarUsuarios();
    this.loading = false;
  },

  methods: {
    async listarUsuarios() {
      const result = await ListUsers();
      if (result.status === 200) {
        this.desserts = result.data;
      }
    },

    editItem(item) {
      this.itemSelect = item;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogActive = false;
      this.dialogDesactive = false;
      this.dialogAcesso = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.sending = true;
      try {
        if (this.editedIndex > -1) {
          const idUser = await userId(this.itemSelect.email);
          Object.assign(this.desserts[this.editedIndex], this.editedItem);
          this.editedItem;
          const result = await editarUsuario(
            idUser.data,
            this.editedItem.nome,
            this.editedItem.email,
            this.editedItem.cpf
          );
          if (result.status === 200) {
            this.$swal(
              "Usuário editado com sucesso",
              `Informações do ${this.editedItem.nome} foi alterado`,
              "success"
            );
          } else {
            this.$swal(
              "Algo deu errado",
              `Não foi possível editar as informações do usuário`,
              "error"
            );
          }
        }
      } catch (error) {
        this.$swal(
          "Algo deu errado",
          `Não foi possível editar as informações do usuário`,
          "error"
        );
      }
      this.sending = false;
      this.close();
    },

    ativaItem(item) {
      this.dialogActive = true;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    async ativaItemConfirm() {
      this.sending = true;
      try {
        const idUser = await userId(this.editedItem.email);
        const result = await ActiveUser(idUser.data);
        if (result.status === 200) {
          this.$swal(
            "Usuário ativado",
            `Usuário ${this.editedItem.nome} foi ativado com sucesso`,
            "success"
          );
        this.desserts[this.editedIndex].isActive = true;
        } else {
          this.$swal(
            "Algo deu errado",
            `Não foi possível ativar o usuário`,
            "error"
          );
        }
      } catch (error) {
        this.$swal(
          "Algo deu errado",
          `Não foi possível ativar o usuário`,
          "error"
        );
      }
      this.sending = false;
      this.closeDelete();
    },

    desativaItem(item) {
      this.dialogDesactive = true;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    async desactiveItemConfirm() {
      this.sending = true;
      try {
        const idUser = await userId(this.editedItem.email);
        const result = await DesActiveUser(idUser.data);
        if (result.status === 200) {
          this.$swal(
            "Usuário desativado",
            `Usuário ${this.editedItem.nome} foi desativado com sucesso`,
            "success"
          );
        this.desserts[this.editedIndex].isActive = false;
        } else {
          this.$swal(
            "Algo deu errado",
            `Não foi possível desativar o usuário`,
            "error"
          );
        }
      } catch (error) {
        this.$swal(
          "Algo deu errado",
          `Não foi possível desativar o usuário`,
          "error"
        );
      }
      this.sending = false;
      this.closeDelete();
    },

    acessoOk(item, ehAdmin) {
      this.dialogAcesso = true;
      this.defaultItem = item;
      if (!ehAdmin) {
        this.msgAdmin = "conceder";
      } else {
        this.msgAdmin = "retirar";
      }
    },

    async acesso() {
      try {
        this.sending = true;
        const result = await acessoUsuario(this.defaultItem.email);
        if (result.status === 200) {
          this.$swal(
            "Sucesso",
            `Permissões alteradas do usuário ${this.defaultItem.nome}`,
            "success"
          );
          this.listarUsuarios();
        } else if (result.status === 500) {
          this.$swal(
            "Algo deu errado",
            `Não foi possível alterar o acesso`,
            "error"
          );
        }
      } catch (error) {
        this.$swal(
          "Algo deu errado",
          `Não foi possível alterar o acesso`,
          "error"
        );
      }
      this.sending = false;
      this.dialogAcesso = false;
    },

    getColor(item) {
      if (item.admin) {
        return "orange";
      } else {
        return "green";
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style>
.toolbarS {
  display: flex;
  justify-content: space-between;
}
</style>
