<template>
  <div
    id="tela-usuario"
    class="d-flex flex-column h-100 justify-content-between"
  >
    <app-header />

    <div class="container data">
      <div class="user-column">
        <h3 v-if="user" v-text="user.nome"></h3>

        <ul v-if="user" class="user-data">
          <li><strong>E-mail:</strong> {{ user.email }}</li>
          <li><strong>CPF:</strong> {{ user.cpf }}</li>
        </ul>
      </div>
      <div class="event-column">
        <inscricoes />
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/Header.vue";
import AppFooter from "../components/Footer.vue";
import inscricoes from "../components/Inscricoes.vue";
import { getOne } from "../services/api";
export default {
  name: "telaDoUser",
  components: {
    AppHeader,
    inscricoes,
    AppFooter,
  },
  data() {
    return {
      logado: true,
      user: null,
      teste: "teste",
      nome: null,
    };
  },
  async mounted() {
    const u = await getOne();
    if (u.status == 200) {
      this.user = u.data;
      this.nome = u.data.nome;
    }
  },
};
</script>

<style scoped lang="scss">
@import "./TelaDoUsuario.scss";
</style>
