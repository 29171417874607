<template>
  <v-card
    class="mx-auto card d-flex flex-column px-3 pt-2 pb-4"
    style="height: 100%"
    max-width="100%"
    outlined
  >
    <v-list-item two-line class="pa-0">
      <v-list-item-content class="pa-0">
        <div style="color:#AAA" class="text-overline">
          Início: {{ dataFormatada }}
        </div>
        <v-list-item-title class="text-h5 mb-1 titulo-evento">
          {{ card.titulo }}
        </v-list-item-title>
        <v-list-item-subtitle style="white-space: inherit !important">{{
          card.descricao
        }}</v-list-item-subtitle>
        <v-list-item-subtitle style="font-weight: bold; padding-top: 1rem; ">
        Vagas Restantes: {{
          card.vagasRestantes
        }} </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions
      class="botoes d-flex gap-2 flex-column flex-sm-row align-start pa-0 mt-8"
    >
      <inscrever @verificarInscricao="turnOffEnrollment" :card="card" v-if="!getInscricao"/>
      <cancelarInscricao @verificarDeinscricao="turnOnEnrollment" :card="card" v-if="getInscricao"/>
      <maisinfos :card="card" />
    </v-card-actions>
  </v-card>
</template>

<script>
import maisinfos from "./MaisInfos";
import inscrever from "./InscricaoEvento.vue";
import cancelarInscricao from "./cancelarInscricao.vue"
import { inscricaoUsuario } from "../services/api";

export default {
  name: "Lapis",
  data() {
  return {
    inscricao: false}
  },
  components: {
    maisinfos,
    inscrever,
    cancelarInscricao,
  },
  props: {
    card: {
      type: Object,
    },
  },
  methods: {
    verifyEnrollment(idCard){
      inscricaoUsuario(idCard).then(result => {
        if (result.status === 200) this.inscricao = !this.inscricao;
      })
      
  },
    turnOnEnrollment(idCard){
      inscricaoUsuario(idCard).then(result => {
        if (result.status === 500) this.inscricao = false;
      })
      
  },
  turnOffEnrollment(idCard){
      inscricaoUsuario(idCard).then(result => {
        if (result.status === 200) this.inscricao = true;
      })
      
  },
    inverterData(lst) {
      let o = "";
      o = lst[1] + lst[2] + lst[0];
      return o;
    },
  },

  computed: {
    getInscricao: function(){
      return this.inscricao;
    },
    dataFormatada: function() {
      return new Date(this.card.dataInicio).toLocaleDateString();
    },
  },
  created() {
  this.verifyEnrollment(this.card.id);
  },
};
</script>

<style>
.v-card {
  border-top: 2px solid black !important;
}

@media (max-width: 556px) {
  .botoes {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }
}
.card {
  background-color: whitesmoke; 
}
</style>
